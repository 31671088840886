import { STORAGE_KEY } from '../constants/generic';

export function saveSsoTokens(auth, refresh) {
  localStorage.setItem(STORAGE_KEY.SSO_TOKEN, auth);
  localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, refresh);
}

export function clearSsoTokens() {
  localStorage.removeItem(STORAGE_KEY.SSO_TOKEN);
  localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
}

export function saveAuthToken(token) {
  localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, token);
}

export function clearAuthToken() {
  localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
}

export function restoreAuthToken() {
  return localStorage.getItem(STORAGE_KEY.AUTH_TOKEN) || '';
}

export function restoreSsoTokens() {
  return {
    auth: localStorage.getItem(STORAGE_KEY.SSO_TOKEN) || '',
    refresh: localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN) || ''
  };
}
