import { getAppObj, parseAuthTokenFromApi } from './utils/utils';
import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducer from './reducers';
import { handleSessionExpired } from './actions/user';
import config from './constants/config';
import endpoints from './constants/endpoints';
import { anyAuthTokenSelector } from './helpers/selectors';
import { restoreAuthToken, restoreSsoTokens } from './utils/tokens';
import { STORAGE_KEY } from './constants/generic';

const windowExist = typeof window !== 'undefined';

const client = axios.create({
  baseURL: config.getApiUrl(),
  responseType: 'json'
});

if (windowExist) getAppObj().axiosDefaults = client.defaults;

const store = configureStore({
  reducer,
  middleware: [promise, axiosMiddleware(client), thunk]
});

export default store;

client.interceptors.request.use((config) => {
  const requestToken = parseAuthTokenFromApi(config.headers['x-auth-token']);
  const getAnyToken = () => anyAuthTokenSelector(store.getState());
  const getAuthToken = () => getAppObj()[STORAGE_KEY.AUTH_TOKEN];
  const getSsoToken = () => getAppObj().keycloak?.token;
  const getStorageToken = () => restoreAuthToken();
  const getStorageSsoToken = () => restoreSsoTokens().auth;

  config.headers['x-auth-token'] =
    requestToken ||
    getAnyToken() ||
    getAuthToken() ||
    getSsoToken() ||
    getStorageToken() ||
    getStorageSsoToken();

  return config;
});

const excludedUrls = [
  endpoints.me,
  endpoints.refresh,
  endpoints.authenticate,
  endpoints.passwordReset
];

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const statusCode = error.response.status;

    if (statusCode === 401) {
      const url = error.response.config.url.split(/[?#]/)[0];
      const exceptions = excludedUrls.includes(url);

      if (!exceptions) {
        store.dispatch(handleSessionExpired());
      }

      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
