import { createSelector } from 'reselect';
import _pick from 'lodash/pick';
import Color from 'color';
import moment from 'moment';
import config from '../constants/config';
import _mapValues from 'lodash/mapValues';

import {
  getShortId,
  createGetFileTypeFinder,
  safe,
  fallbackObj,
  fallbackArray,
  isObjEmpty,
  getAppObj,
  strToBool
} from '../utils/utils';

import {
  CUSTOM_FIELD_STATUS_CONDITIONED,
  CUSTOM_FIELD_STATUS_YES,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_FILE,
  DOCUMENT_STATUS,
  FILE_STATUS_BACK,
  FILE_STATUS_FRONT,
  FILE_STATUS_SELFIE,
  MEMBER_STATUS_APPLIED,
  MEMBER_STATUSES,
  USAGE_TYPE_ALL,
  USAGE_TYPE_BUSINESS,
  USAGE_TYPE_PRIVATE,
  VEHICLE_USAGES
} from '../constants/backend';
import _find from 'lodash/find';
import { getFormValues } from 'redux-form';
import { getMessage, isRMF_companyId } from '../utils/utils';
import { searchBookingsFields, USER_FORM_NAMES } from '../constants/form';
import { customFieldIsVisible } from './components';
import { getObjectFromQuery } from './index';
import memoizeOne from 'memoize-one';
import { getKeycloakUrl } from '../utils/keycloak';

// end with '...Selector' when selecting from 'state'
// do not use {} or [] on selectors return
// it recreates an object everytime and triggers re-render

export const messagesSelector = (state = {}) => {
  const { messages } = state.i18n || {};
  return messages || fallbackObj;
};

export const userFilesSelector = (state) => {
  const { files } = state.user;
  return files || fallbackObj;
};

export const userCompanySelector = (state) => {
  const { user } = state;
  const { data } = user || {};
  const { company } = data || {};
  return company || fallbackObj;
};

export const hotlineSelector = createSelector(userCompanySelector, (companyInfos) => {
  const { computedConfiguration } = companyInfos || {};
  const { hotline } = computedConfiguration || {};
  return hotline || fallbackObj;
});

export const addToCalendarDataSelector = (state) => {
  const { addToCalendarData } = state.popup;
  return addToCalendarData;
};

export const productNameSelector = (state) => {
  return safe(() => state.brand.branded.productName) || 'Carsharing';
};

export const currentBrandSelector = (state) => {
  const { branded } = state.brand;
  return branded || fallbackObj;
};

export const currentThemeSelector = createSelector(
  currentBrandSelector,
  (currentBrand = {}) => currentBrand.theme || fallbackObj
);

export const localeSelector = (state) => {
  const { i18n } = state;
  const { locale } = i18n;
  return locale || 'fr';
};

export const userCompanyIdSelector = createSelector(
  userCompanySelector,
  (userCompany = {}) => userCompany.id
);

export const userCompanyCurrencySelector = createSelector(
  userCompanySelector,
  (userCompany = {}) => userCompany.currency
);

export const userCompanyPaymentProviderSelector = createSelector(
  userCompanySelector,
  (userCompany = {}) => userCompany.paymentProvider
);

export const sharedCompanySelector = (state) => {
  const { data, registerCompany } = state.user;
  const { company } = data || {};
  return registerCompany || company;
};

export const creationSuccessDataSelector = (state) =>
  state.user.creationSuccessData || fallbackObj;

export const preBookingStatusSelector = (state) => state.booking.preBookingStatus;

export const sharedCompanyNameSelector = createSelector(
  sharedCompanySelector,
  messagesSelector,
  (company = {}, messages = {}) => {
    return company.name || getMessage(messages, 'generic.company');
  }
);

export const userIdSelector = (state) => {
  const { user } = state;
  const { data } = user || {};
  const { id } = data || {};
  return id;
};

const bookingFieldsSelector = (state) => {
  const { customFields } = state;
  const { bookingFields } = customFields;
  return bookingFields || fallbackArray;
};

export const bookingValuesSelector = (state) => {
  const { customFields } = state;
  const { bookingValues } = customFields;
  return bookingValues || fallbackArray;
};

export const memberFieldsSelector = (state) => {
  const { customFields } = state;
  const { memberFields } = customFields;
  return memberFields || fallbackArray;
};

// TODO: carefully remove this or memberCustomFieldsValuesSelector
export const memberValuesSelector = (state) => {
  const { customFields } = state;
  const { memberValues } = customFields;
  return memberValues || fallbackArray;
};

export const selectCustomFieldsNames = memoizeOne((customFields = []) => {
  return customFields.map((field) => {
    const { companyCustomField } = field || {};
    const { id } = companyCustomField || {};
    return id;
  });
});

export const selectFieldIdsByPosition = memoizeOne((customFields = []) => {
  const ret = {};

  customFields.forEach((field) => {
    const { companyCustomField } = field || {};
    const { id, position } = companyCustomField || {};
    ret[position] = id;
  });

  return ret;
});

const fieldStatusVisible = (field) => {
  const { visible: visibility } = field || {};

  return (
    visibility === CUSTOM_FIELD_STATUS_YES ||
    visibility === CUSTOM_FIELD_STATUS_CONDITIONED
  );
};

const fieldUsageVisible = (field, usageType) => {
  const { usageType: fieldType } = field || {};

  if (!usageType || !fieldType) return true;
  if (fieldType === USAGE_TYPE_ALL) return true;
  if (fieldType === usageType) return true;
};

const fieldVehicleUsageVisible = (field, vehicleUsage) => {
  const { vehicleUsages } = field || {};
  if (!isObjEmpty(vehicleUsages) && vehicleUsage) {
    return vehicleUsages.includes(vehicleUsage);
  }
  return true;
};

const parseCustomFields = (
  companyFields = [],
  fieldsValues = [],
  bookingUsage,
  vehicleUsage
) => {
  const visibleCompanyFields = companyFields.filter((field) => {
    return (
      fieldStatusVisible(field) &&
      fieldUsageVisible(field, bookingUsage) &&
      fieldVehicleUsageVisible(field, vehicleUsage)
    );
  });

  const flatCopyMemberValues = [...fieldsValues];

  const selectedCustomFields = visibleCompanyFields.map((companyField) => {
    const newField = {
      companyCustomField: companyField
    };

    flatCopyMemberValues.forEach((memberField, index, object) => {
      const { id: companyFieldId } = companyField || {};
      const { companyCustomField, value: memberValue } = memberField || {};
      const { id: memberFieldId } = companyCustomField || {};

      if (companyFieldId === memberFieldId) {
        newField.value = memberValue;
        object.splice(index, 1); // delete item when found
      }
    });

    return newField;
  });

  const getFieldPosition = (field) => {
    const { companyCustomField } = field || {};
    const { position } = companyCustomField || {};
    return position || 0;
  };

  return selectedCustomFields.sort((a, b) => getFieldPosition(a) - getFieldPosition(b));
};

export const bookingUsageTypeSelector = (state) => {
  const { selectedVehicle } = state.booking || {};
  const { carSharingInfo } = selectedVehicle || {};
  const { usageType } = carSharingInfo || {};

  return usageType;
};

export const vehicleUsageTypeSelector = (state) => {
  const { selectedVehicle } = state.booking || {};
  const { vehicle } = selectedVehicle || {};
  const { usage } = vehicle || {};

  return usage;
};

export const bookingCustomFieldsSelector = createSelector(
  bookingFieldsSelector,
  bookingValuesSelector,
  bookingUsageTypeSelector,
  vehicleUsageTypeSelector,
  parseCustomFields
);

export const profileCustomFieldsSelector = createSelector(
  memberFieldsSelector,
  memberValuesSelector,
  parseCustomFields
);

export const userDataSelector = (state) => {
  const { user } = state;
  const { data } = user || {};
  return data || fallbackObj;
};

export const userDataUpdatingSelector = (state) => state.user.userDataUpdating;

export const userEmailSelector = createSelector(userDataSelector, (data) => data?.login);

export const memberCustomFieldsValuesSelector = createSelector(
  userDataSelector,
  (userData = {}) => {
    const { memberCustomValues } = userData || {};
    return memberCustomValues;
  }
);

export const userFirstNameSelector = createSelector(
  userDataSelector,
  (data = {}) => data.firstName
);

export const userContractSelector = createSelector(userDataSelector, (userData) => {
  const { company } = userData;
  const { contract } = company || {};
  return contract || fallbackObj;
});

export const showManagerEmailFieldSelector = createSelector(
  sharedCompanySelector,
  (companyData = {}) => {
    return companyData.sendEmailsToManager;
  }
);

export const showSpecialFieldsSelector = createSelector(
  showManagerEmailFieldSelector,
  (...args) => {
    return args.some((fieldPresent) => fieldPresent);
  }
);

export const companySitesSelector = createSelector(userDataSelector, (userData) => {
  const { company } = userData || {};
  const { sites } = company || {};
  return sites || fallbackArray;
});

export const profileLanguageInitialValuesSelector = createSelector(
  userDataSelector,
  (userData) => {
    const { locale } = userData;
    return { language: locale };
  }
);

export const userStatusSelector = createSelector(userDataSelector, (userData) => {
  const { status } = userData || {};
  return status;
});

export const drivingLicenceStatusSelector = createSelector(
  userDataSelector,
  (userData) => {
    const { drivingLicence } = userData || {};
    const { status } = drivingLicence || {};
    return status;
  }
);

export const personalDetailsInitialValuesSelector = createSelector(
  userDataSelector,
  (userData) => {
    const { civility, lastName, firstName, address, birthDate, login, phoneNumber } =
      userData;

    const { city, postalCode, country, streetName } = address || {};
    const { countryCode, nationalNumber } = phoneNumber || {};

    return {
      civility,
      lastName,
      firstName,
      login,
      address: streetName,
      country,
      postalCode,
      city,
      birthDate,
      phoneNumber: {
        phonePrefix: countryCode,
        phoneSuffix: nationalNumber,
        valid: true
      }
    };
  }
);

export const drivingLicenceFormInitialValuesSelector = createSelector(
  userDataSelector,
  (userData) => {
    const { drivingLicence } = userData;

    const findFront = createGetFileTypeFinder(FILE_STATUS_FRONT);
    const findBack = createGetFileTypeFinder(FILE_STATUS_BACK);

    const { cityDeliverance, deliveranceDate, licenceNumber, expirationDate, files } =
      drivingLicence || {};

    const frontFileData = _find(files, findFront);
    const backFileData = _find(files, findBack);

    const { fileId: documentFrontId } = frontFileData || {};
    const { fileId: documentBackId } = backFileData || {};

    return {
      cityDeliverance,
      deliveranceDate,
      licenceNumber,
      expirationDate,
      expirationDateNotPresent: !expirationDate,
      documentFront: {
        fileId: documentFrontId,
        fileName: getShortId(documentFrontId)
      },
      documentBack: {
        fileId: documentBackId,
        fileName: getShortId(documentBackId)
      }
    };
  }
);

export const identityDocumentFormInitialValuesSelector = createSelector(
  userDataSelector,
  (userData) => {
    const { identityDocument } = userData;

    const findFront = createGetFileTypeFinder(FILE_STATUS_FRONT);
    const findBack = createGetFileTypeFinder(FILE_STATUS_BACK);

    const { files } = identityDocument || {};

    const frontFileData = _find(files, findFront);
    const backFileData = _find(files, findBack);

    const { fileId: documentFrontId } = frontFileData || {};
    const { fileId: documentBackId } = backFileData || {};

    return {
      documentFront: {
        fileId: documentFrontId,
        fileName: getShortId(documentFrontId)
      },
      documentBack: {
        fileId: documentBackId,
        fileName: getShortId(documentBackId)
      }
    };
  }
);

export const selfieFormInitialValuesSelector = createSelector(
  userDataSelector,
  (userData) => {
    const { identityDocument } = userData;
    const findFront = createGetFileTypeFinder(FILE_STATUS_SELFIE);
    const { files } = identityDocument || {};
    const selfieFileData = _find(files, findFront);
    const { fileId } = selfieFileData || {};

    return {
      selfieImage: {
        fileId,
        fileName: getShortId(fileId)
      }
    };
  }
);

export const userSettingsSelector = (state) => state.user.settings || fallbackObj;

export const notificationsFormInitialValuesSelector = createSelector(
  userSettingsSelector,
  (settings) => {
    const { smsBeforeDepartureTime, smsBeforeArrivalTime } = settings;

    settings = { ...settings };

    if (smsBeforeArrivalTime > 0) {
      settings.smsBeforeArrivalEnabled = true;
      settings.smsBeforeArrivalTime = String(smsBeforeArrivalTime);
    } else settings.smsBeforeArrivalTime = '15';

    if (smsBeforeDepartureTime > 0) {
      settings.smsBeforeDepartureEnabled = true;
      settings.smsBeforeDepartureTime = String(smsBeforeDepartureTime);
    } else settings.smsBeforeDepartureTime = '15';

    return settings;
  }
);

export const mapCountriesSelector = (state) =>
  state.brand.branded.mapCountries || fallbackObj;
export const searchBookingsFormSelector = (state) =>
  state.form.searchBookings || fallbackObj;

export const searchRemoteCompanyIdSelector = createSelector(
  mapCountriesSelector,
  searchBookingsFormSelector,
  (mapCountries, form) => {
    const { values: formData } = form;
    const { location } = formData || {};
    let { countryISO } = location || {};

    if (!countryISO) {
      countryISO = safe(() => Intl.DateTimeFormat().resolvedOptions().locale.slice(-2));
    }

    const companyObj = mapCountries[countryISO] || {};
    return companyObj.companyId;
  }
);

export const isRenaultFranceUserSelector = createSelector(
  userCompanyIdSelector,
  (userCompanyId) => {
    return isRMF_companyId(userCompanyId);
  }
);

export const selectCustomFieldsInitialValues = memoizeOne((fields = []) => {
  const ret = {};

  fields.forEach((field) => {
    const { value, companyCustomField } = field || {};
    const { id, fieldType } = companyCustomField || {};

    if (value) {
      if (fieldType === CUSTOM_FIELD_TYPE_FILE) {
        ret[id] = {
          fileId: value,
          fileName: getShortId(value)
        };
      } else if (fieldType === CUSTOM_FIELD_TYPE_BOOLEAN) {
        ret[id] = strToBool(value);
      } else ret[id] = value;
    }
  });

  return ret;
});

export const specialFieldsInitialValuesSelector = createSelector(
  userDataSelector,
  (userData = {}) => {
    const { managerEmail } = userData;
    return { managerEmail };
  }
);

export const acceptTermsInitialValuesSelector = createSelector(
  userDataSelector,
  (userData = {}) => {
    const { commercialOffers: commercialPurposes, termsAndConditionsAcceptance } =
      userData;
    const {
      termsOfUseAccepted: termsOfUse,
      termsOfSubscriptionAccepted: termsOfSubscription,
      privacyPolicyRead: privacyPolicy
    } = termsAndConditionsAcceptance || {};

    return {
      termsOfUse,
      termsOfSubscription,
      privacyPolicy,
      commercialPurposes
    };
  }
);

export const customFieldsValuesSelector = createSelector(
  getFormValues(USER_FORM_NAMES.CUSTOM_FIELDS),
  (values) => {
    const { _validate, ...rest } = values || {};
    return rest;
  }
);

export const registerUpcomingBookingsSelector = (state) => {
  return state.booking.registerUpcomingBookings;
};

export const registerUpcomingBookingsStatusSelector = createSelector(
  registerUpcomingBookingsSelector,
  (bookings) => {
    const status = {
      hasPrivateBooking: false,
      hasBusinessBooking: false
    };

    if (bookings) {
      bookings.forEach((booking = {}) => {
        const { carSharingInfo } = booking;
        const { usageType } = carSharingInfo || {};

        if (usageType === USAGE_TYPE_PRIVATE) {
          status.hasPrivateBooking = true;
        } else if (usageType === USAGE_TYPE_BUSINESS) {
          status.hasBusinessBooking = true;
        }
      });
    }

    return status;
  }
);

export const checkCustomFieldsPresent = memoizeOne(
  (customFields = [], customFieldsValues = {}) => {
    return customFields.some((field) => {
      return customFieldIsVisible(
        field,
        customFieldsValues,
        selectFieldIdsByPosition(customFields)
      );
    });
  }
);

// TODO: include not accepted terms fields
export const showMoreInfoPageSelector = createSelector(
  profileCustomFieldsSelector,
  customFieldsValuesSelector,
  showSpecialFieldsSelector,
  (customFields, customFieldsValues, showSpecialFields) =>
    checkCustomFieldsPresent(customFields, customFieldsValues) || showSpecialFields
);

export const acceptTermsValuesSelector = createSelector(
  getFormValues(USER_FORM_NAMES.ACCEPT_TERMS),
  (values) => {
    const { _validate, ...rest } = values || {};
    return rest;
  }
);

export const chorusFieldsValuesSelector = createSelector(
  getFormValues('chorusForm'),
  (values) => {
    const { _validate, ...rest } = values || {};
    return rest;
  }
);

export const specialFieldsValuesSelector = createSelector(
  getFormValues(USER_FORM_NAMES.SPECIAL_FIELDS),
  (values) => {
    const { _validate, ...rest } = values || {};
    return rest;
  }
);

export const bookingEditPopupDataSelector = (state) => state.booking.editBookingPopupData;

export const pageSettingsSelector = createSelector(
  currentBrandSelector,
  (currentBrand) => currentBrand.pageSettings
);

export const personalDetailsConfigSelector = createSelector(
  pageSettingsSelector,
  (settings) => safe(() => settings.personalDetailsForm)
);

export const personalDetailsSubscribeConfigSelector = createSelector(
  pageSettingsSelector,
  (settings) => safe(() => settings.subscribeForm.personalInfo)
);

export const brandCompanyInitialValuesSelector = createSelector(
  pageSettingsSelector,
  (brandCompanySettings) => {
    const { subscribeForm } = brandCompanySettings || {};
    if (subscribeForm) {
      return _mapValues(subscribeForm, (page) =>
        _mapValues(page, (field) => field.default)
      );
    }
  }
);

export const editBookingInitialValuesSelector = createSelector(
  bookingEditPopupDataSelector,
  (editData = {}) => {
    const { start, end } = editData || {};
    const { date: startDate, address, coordinates } = start || {};
    const { date: endDate } = end || {};

    const { country, ...locationProps } = address || {};
    const dateFormat = 'YYYY-MM-DD';
    const timeFormat = 'HH:mm';

    const location = {
      ...locationProps,
      countryISO: country,
      coordinates
    };

    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    return {
      startDate: momentStartDate.format(dateFormat),
      endDate: momentEndDate.format(dateFormat),
      startTime: momentStartDate.format(timeFormat),
      endTime: momentEndDate.format(timeFormat),
      location
    };
  }
);

export const selectUrlQuery = () => window.location.search;

export const selectSearchQueryValues = memoizeOne((query) => {
  const fieldValues = _pick(getObjectFromQuery(query), searchBookingsFields);

  const { location, places } = fieldValues || {};
  const { coordinates } = location || {};
  const { latitude, longitude } = coordinates || {};

  if (latitude) coordinates.latitude = +latitude;
  if (longitude) coordinates.longitude = +longitude;
  if (places) fieldValues.places = +places;

  return fieldValues;
});

export const searchValuesSelector = (state) => {
  const formValues = getFormValues('searchBookings')(state);
  if (isObjEmpty(formValues)) {
    return selectSearchQueryValues(selectUrlQuery());
  }
  return formValues;
};

export const selectValidRegisterCompanyId = (brand = {}) => {
  const { mapCountries } = brand;

  if (mapCountries) {
    const correctKey = Object.keys(mapCountries).find((isoCode) => {
      const { companyId } = mapCountries[isoCode] || {};
      return companyId && !isRMF_companyId(companyId);
    });

    return mapCountries[correctKey].companyId;
  }
};

export const userProfilesSelector = (state) => state.user.profiles;

export const inactiveProfilesSelector = createSelector(
  userProfilesSelector,
  (profiles) => {
    if (profiles) {
      const ret = profiles.reduce((ret, profile) => {
        const { active } = profile || {};
        if (!active) ret.push(profile);
        return ret;
      }, []);

      if (ret.length) return ret;
    }
  }
);

export const registerCompanySelector = (state) => state.user.registerCompany;
export const registerCompanyIdSelector = (state) => registerCompanySelector(state)?.id;

export const activeProfileSelector = createSelector(
  userProfilesSelector,
  userDataSelector,
  (profiles, userData) => {
    if (profiles) {
      return profiles.find((profile = {}) => profile.active);
    }

    if (userData) {
      const { company } = userData;
      const { id, name } = company || {};

      if (id && name) {
        return {
          superCompanyId: id,
          superCompanyName: name
        };
      }
    }
  }
);

export const activeProfileCompanyIdSelector = createSelector(
  activeProfileSelector,
  (profile) => profile && profile.superCompanyId
);

export const mapProfilesToCompanyIds = memoizeOne(
  (profiles) => profiles && profiles.map((profile = {}) => profile.superCompanyId)
);

export const profileCompanyIdsSelector = createSelector(
  userProfilesSelector,
  mapProfilesToCompanyIds
);

export const canLinkProfileBasedOnIds = memoizeOne(
  (registerCompanyId, profileCompanyIds) => {
    if (registerCompanyId && profileCompanyIds) {
      return !profileCompanyIds.includes(registerCompanyId);
    }
    return true;
  }
);

export const canAddNewAccountSelector = createSelector(
  registerCompanyIdSelector,
  profileCompanyIdsSelector,
  canLinkProfileBasedOnIds
);

export const isRegisterIncompleteUserSelector = (state) =>
  state.user.isRegisterIncompleteUser;

export const isExternalIncompleteUserSelector = (state) =>
  state.keycloak.isExternalIncompleteUser;

export const isProfileIncompleteUserSelector = createSelector(
  userIdSelector,
  isRegisterIncompleteUserSelector,
  isExternalIncompleteUserSelector,
  profileCompanyIdsSelector,
  canAddNewAccountSelector,
  (
    userId,
    isRegisterIncompleteUser,
    isExternalIncompleteUser,
    profileCompanyIds,
    canAddNewAccount
  ) => {
    return (
      userId &&
      !isRegisterIncompleteUser &&
      !isExternalIncompleteUser &&
      profileCompanyIds?.length &&
      canAddNewAccount
    );
  }
);

// do not set to 'false' if not all data is fetched
export const drivingLicenseAllowedSelector = createSelector(
  registerCompanySelector,
  drivingLicenceStatusSelector,
  isRegisterIncompleteUserSelector,
  (company, status, isIncompleteUser) => {
    if (!company?.id) return true;
    const { drivingLicenceRequired } = company || {};
    const licenceApproved = status === DOCUMENT_STATUS.APPROVED;
    if (isIncompleteUser && licenceApproved) return false;
    return drivingLicenceRequired;
  }
);

// do not set to 'false' if not all data is fetched
export const identityDocumentAllowedSelector = createSelector(
  registerCompanySelector,
  (company) => {
    if (!company?.id) return true;
    const { identityDocumentRequired } = company || {};
    return identityDocumentRequired;
  }
);

export const profilePrivateRentalAllowedSelector = createSelector(
  profileCompanyIdsSelector,
  mapCountriesSelector,
  (profileCompanyIds, mapCountries) => {
    if (profileCompanyIds && mapCountries) {
      return Object.keys(mapCountries).reduce((ret, isoCode) => {
        const { [isoCode]: isoCountry } = mapCountries || {};
        const { companyId } = isoCountry || {};

        if (profileCompanyIds.includes(companyId)) return ret;
        if (companyId) ret.push(companyId);

        return ret;
      }, []).length;
    }
  }
);

export const keycloakRemoteConfigSelector = (state) => state.keycloak.remoteConfig;

// https://glide-mobility.atlassian.net/wiki/spaces/WAPP/pages/3767074817/S3+configuration
export const parseKeycloakConfig = (s3, be) => {
  const { useBrandConfig } = s3 || {};
  if (useBrandConfig) be = s3;
  be = be || {};

  const c = {
    buttonText: s3.buttonText || 'SSO',
    useBrandConfig,
    initSettings: {
      clientId: s3.clientId || be.clientId,
      realm: s3.realm || be.realm,
      url: s3.keycloakBaseUrl || be.keycloakBaseUrl || getKeycloakUrl()
    },
    superCompanyId: s3.superCompanyId || be.superCompanyId,
    identityProvider: s3.identityProvider || be.identityProvider
  };

  if (!s3.enabled) return;
  if (
    c.initSettings.clientId &&
    c.initSettings.realm &&
    c.identityProvider &&
    (c.superCompanyId || s3.useOnlySsoForLogin)
  ) {
    return c;
  }
  return { buttonText: c.buttonText };
};

export const brandKeycloakSelector = createSelector(
  currentBrandSelector,
  (currentBrand = {}) => currentBrand.keycloak
);

export const keycloakConfigSelector = createSelector(
  brandKeycloakSelector,
  keycloakRemoteConfigSelector,
  parseKeycloakConfig
);

export const getIncompleteUserStatus = (data) => {
  const { status } = data || {};
  return status === MEMBER_STATUSES.TO_COMPLETE;
};

export const getAppliedUserStatus = (data) => {
  const { status } = data || {};
  return status === MEMBER_STATUS_APPLIED;
};

export const isIncompleteUserSelector = createSelector(
  userDataSelector,
  getIncompleteUserStatus
);

export const isAppliedUserSelector = createSelector(
  userDataSelector,
  getAppliedUserStatus
);

export const brandListSelector = (state) => state.brand.brandsList || fallbackObj;
export const remoteConfigUrlSelector = (state) =>
  state.brand.remoteBrandFilePart || fallbackObj;

export const isPrimaryColorDarkSelector = createSelector(
  currentBrandSelector,
  (brand) => {
    const { colors } = brand.theme || {};
    const { primary, secondary } = colors || {};
    const colorPrimary = Color(primary);

    if (secondary) {
      const colorSecondary = Color(secondary);
      const whiteContrast = colorSecondary.contrast(Color.rgb(255, 255, 255));
      const blackContrast = colorSecondary.contrast(Color.rgb(0, 0, 0));

      return whiteContrast < blackContrast;
    }
    return colorPrimary.luminosity() < config.luminosityRatio;
  }
);

export const isSecondaryColorDarkSelector = createSelector(
  currentBrandSelector,
  (brand) => {
    const { colors } = brand.theme || {};
    const { secondary } = colors || {};
    const colorSecondary = Color(secondary);

    return colorSecondary.luminosity() < config.luminosityRatio;
  }
);
export function isReplacementVehicleBooking(booking) {
  const { carSharingInfo } = booking || {};
  const { replacementVehicle } = carSharingInfo || {};
  return replacementVehicle;
}

export function isTestDriveBooking(booking) {
  const { vehicleUsageAtBookingCreation } = booking || {};
  return vehicleUsageAtBookingCreation === VEHICLE_USAGES.TEST_DRIVE;
}

export function isPublicServiceBooking(booking) {
  const { vehicleUsageAtBookingCreation } = booking || {};
  return vehicleUsageAtBookingCreation === VEHICLE_USAGES.PUBLIC_SERVICE;
}

export function isPreBookng(booking) {
  const { carSharingInfo } = booking || {};
  const { preBooked } = carSharingInfo || {};
  return preBooked;
}

export const timePickerIntervalSelector = createSelector(
  currentBrandSelector,
  (currentBrand) => {
    const { bookingTimeUnit } = currentBrand;
    return bookingTimeUnit || 30;
  }
);

export function getActionError(action) {
  const { response } = action.error || {};
  const { data: error } = response || {};
  return error || '';
}

export const brandIdSelector = createSelector(
  currentBrandSelector,
  (currentBrand) => currentBrand.brandId
);

export const brandCompaniesSelector = (state) => state.user.brandCompanies;

export const memberInKeycloakSelector = (state) => state.user.memberInKeycloak;
export const userImpersonatedSelector = (state) => state.impersonate.userImpersonated;
export const userTemporaryPasswordSelector = (state) => state.user.temporaryPassword;

export function isSsoOnlyResp(resp) {
  const { ssoConfiguration } = safe(() => resp.payload.data) || {};
  const { allowRegistrationWithoutSso } = ssoConfiguration || {};
  return allowRegistrationWithoutSso === false;
}

export const footerDataSelector = createSelector(
  currentBrandSelector,
  (brand) => brand.footerData || fallbackObj
);

export const legalInfoUrlSelector = createSelector(footerDataSelector, (footerData) =>
  safe(() => footerData.legalInformationUrl.trim())
);

export const personalDataUrlSelector = createSelector(footerDataSelector, (footerData) =>
  safe(() => footerData.personalDataUrl.trim())
);

export const aboutUrlSelector = createSelector(footerDataSelector, (footerData) =>
  safe(() => footerData.aboutUrl.trim())
);

export const termsOfUseUrlSelector = createSelector(sharedCompanySelector, (company) =>
  safe(() => company.termsOfUseUrl.trim())
);

export const termsOfSubscriptionUrlSelector = createSelector(
  sharedCompanySelector,
  (company) => safe(() => company.termsOfSubscriptionUrl.trim())
);

export const privacyPolicyUrlSelector = createSelector(sharedCompanySelector, (company) =>
  safe(() => company.privacyPolicyUrl.trim())
);

export const additionalLegalDocumentSelector = createSelector(
  userCompanySelector,
  (data) => data?.additionalLegalDocument || fallbackObj
);
export const showAppLoaderSelector = (state) => {
  return state.brand.showAppLoader || state.user.showSubscribeLoader;
};

export const saveGlobalState = (state) => {
  getAppObj().state = state;
};

export const getGlobalState = () => {
  return getAppObj().state;
};

export const authTokenSelector = (state) => state.user.authToken;
export const ssoTokensSelector = (state) => state.user.ssoTokens;

export const anyAuthTokenSelector = createSelector(
  authTokenSelector,
  ssoTokensSelector,
  (token, tokens) => token || tokens?.auth
);
