export const validateAccount = 'validate-account';

export const creation = {
  container: 'register',
  credentials: 'account',
  activate: 'activate',
  info: 'info',
  files: 'files',
  drivingLicense: 'drivingLicense',
  identityDocument: 'identityDocument',
  selfie: 'selfie',
  moreInfo: 'moreInfo',
  id: 'companyId',
  success: 'success'
};
