import defaultBrandData from '../mocks/defaultBrand.json';
import config from '../constants/config';

import {
  addSepPart,
  addStrPart,
  getAppObj,
  getFileExt,
  isLocalEnv,
  jsonParseSafe
} from '../utils/utils';

import { STORAGE_KEY } from '../constants/generic';
import isEmptyObj from 'lodash/isEmpty';
import { links } from '../constants/links';
import { defaultDesc, defaultSiteName, defaultTitle } from '../constants/meta-tags';

// Note: localStorage is needed for worldpay redirect

const localStorageConfig = jsonParseSafe(localStorage.getItem(STORAGE_KEY.CONFIG));

const localStorageRawContract = jsonParseSafe(
  localStorage.getItem(STORAGE_KEY.RAW_CONTRACT)
);

const defaultBrand =
  localStorageConfig ||
  jsonParseSafe(sessionStorage.getItem(STORAGE_KEY.CONFIG)) ||
  defaultBrandData;

const defaultContract =
  localStorageRawContract ||
  jsonParseSafe(sessionStorage.getItem(STORAGE_KEY.RAW_CONTRACT)) ||
  defaultBrandData.contract;

if (localStorageConfig) localStorage.removeItem(STORAGE_KEY.CONFIG);
if (localStorageRawContract) localStorage.removeItem(STORAGE_KEY.RAW_CONTRACT);

function getBrandProps(rawBrand, userContract) {
  const { contract: rawContract } = rawBrand || {};
  const branded = { ...rawBrand };

  if (!isEmptyObj(userContract)) branded.contract = userContract;

  sessionStorage.setItem(STORAGE_KEY.CONFIG, JSON.stringify(branded));
  sessionStorage.setItem(STORAGE_KEY.RAW_CONTRACT, JSON.stringify(rawContract));

  return {
    branded,
    rawContract
  };
}

function setMetaTags(action) {
  const { rawBrand } = action;
  const { favicon, metaTitle, metaDescription, productName, productTagLine } =
    rawBrand || {};

  const titleFallback = addStrPart(productName, addSepPart(': ', productTagLine));
  const titleText = metaTitle || titleFallback || defaultTitle;
  const descText = metaDescription || defaultDesc;
  const faviconUrl = favicon || links.defaultFavicon;

  const faviconEl = document.getElementById('favicon');
  const titleEl = document.getElementById('meta-title');
  const descEl = document.getElementById('meta-desc');

  const googleTitleEl = document.getElementById('google-title');
  const googleDescEl = document.getElementById('google-desc');

  const twitterTitleEl = document.getElementById('twitter-title');
  const twitterDescEl = document.getElementById('twitter-desc');

  const ogTitleEl = document.getElementById('og-title');
  const ogDescEl = document.getElementById('og-desc');
  const ogSiteNameEl = document.getElementById('og-site-name');

  if (faviconEl) faviconEl.href = faviconUrl;
  if (titleEl) titleEl.innerHTML = titleText;
  if (descEl) descEl.content = descText;

  if (googleTitleEl) googleTitleEl.content = titleText;
  if (googleDescEl) googleDescEl.content = descText;

  if (twitterTitleEl) twitterTitleEl.content = titleText;
  if (twitterDescEl) twitterDescEl.content = descText;

  if (ogTitleEl) ogTitleEl.content = titleText;
  if (ogDescEl) ogDescEl.content = descText;
  if (ogSiteNameEl) ogSiteNameEl.content = productName || defaultSiteName;

  sessionStorage.setItem('meta-title', titleText);
  sessionStorage.setItem('favicon', faviconUrl);
}

function addFont(fontUrl, fontWeight) {
  const fontExt = getFileExt(fontUrl);

  const fontFamilyStyle = "font-family: 'MainFont';";
  const fontUrlStyle = `src: url('${fontUrl}') format('${fontExt}');`;
  const fontWeightStyle = `font-weight: ${fontWeight};`;

  if (fontExt) {
    return `@font-face { ${fontFamilyStyle} ${fontUrlStyle} ${fontWeightStyle} } \n`;
  } else return '';
}

function setFontData(action) {
  const { rawBrand } = action;
  const { font } = rawBrand || {};
  const { light, regular, medium, bold } = font || {};

  const oldFontStyle = document.getElementById('main-font');

  if (regular) {
    const newFontStyle = document.createElement('style');

    newFontStyle.id = 'main-font';
    newFontStyle.textContent =
      addFont(light, 300) +
      addFont(regular, 400) +
      addFont(medium, 500) +
      addFont(bold, 700);
    document.head.appendChild(newFontStyle);
  }

  if (oldFontStyle) {
    document.head.removeChild(oldFontStyle);
  }

  sessionStorage.setItem('font-light', light);
  sessionStorage.setItem('font-regular', regular);
  sessionStorage.setItem('font-medium', medium);
  sessionStorage.setItem('font-bold', bold);
}

function setApiUrl(action) {
  const { rawBrand } = action;
  const { axiosDefaults } = getAppObj();
  const { apiBaseUrl } = rawBrand || {};

  if (apiBaseUrl) {
    getAppObj().apiUrl = apiBaseUrl;
    window.sessionStorage.setItem('API_URL', apiBaseUrl);
    if (axiosDefaults) axiosDefaults.baseURL = apiBaseUrl;
    if (isLocalEnv()) config.env = config.getLocalEnv();
  }
}

function setDynamicData(action) {
  setMetaTags(action);
  setFontData(action);
  setApiUrl(action);
}

const defaultState = {
  branded: defaultBrand,
  rawContract: defaultContract,
  remoteBrandFilePart: '',
  showAppLoader: true,
  configFetched: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'brand/GET_ONE_SUCCESS': {
      const { rawBrand, userContract } = action;

      setDynamicData(action);
      state = { ...state, ...getBrandProps(rawBrand, userContract) };

      break;
    }

    case 'brand/SET_CONFIG_FETCHED_STATE': {
      state = { ...state, configFetched: action.payload };
      break;
    }

    case 'brand/SET_APP_LOADER_STATE': {
      state = { ...state, showAppLoader: action.payload };
      break;
    }

    case 'user/LOGOUT': {
      const rawBrand = { ...state.branded, contract: state.rawContract };
      state = { ...state, ...getBrandProps(rawBrand) };
      break;
    }

    case 'user/REFRESH_SUCCESS':
    case 'user/LOGIN_SUCCESS':
    case 'user/GET_INFO_SUCCESS':
    case 'keycloak/VERIFY_USER_SUCCESS': {
      const rawBrand = state.branded;
      const data = action.payload.data || {};
      const { company } = data || {};
      const { contract: userContract } = company || {};

      if (!isEmptyObj(userContract)) {
        state = { ...state, ...getBrandProps(rawBrand, userContract) };
      }

      break;
    }

    default: {
      break;
    }
  }

  return state;
}
