import { browserHistory } from '../helpers/components';
import _get from 'lodash/get';

import { MEMBER_STATUS_APPLIED } from '../constants/backend';

import {
  safe,
  gtmPush,
  sessionStorageGetJson,
  getMessage as t,
  getPrevAction,
  parseAuthTokenFromApi,
  getAppObj
} from '../utils/utils';

import { STORAGE_KEY } from '../constants/generic';
import {
  messagesSelector,
  getIncompleteUserStatus,
  getActionError,
  getGlobalState,
  getAppliedUserStatus
} from '../helpers/selectors';
import { getErrorMsg } from '../helpers/errors';
import { addActionError } from '../utils/flashMessage/creator';

import {
  clearAuthToken,
  clearSsoTokens,
  restoreAuthToken,
  restoreSsoTokens,
  saveAuthToken,
  saveSsoTokens
} from '../utils/tokens';

function getErrorStatus(action) {
  const { error: errorResp } = action;
  const { response } = errorResp || {};
  const { status: errorStatus } = response || {};
  return errorStatus;
}

function sessionSetMemberInKeycloak(status) {
  sessionStorage.setItem(STORAGE_KEY.IN_SSO, JSON.stringify(status));
}

// specific set of a default value (check for possible browser responses)
function sessionGetMemberInKeycloakIn() {
  const status = sessionStorageGetJson(STORAGE_KEY.IN_SSO);
  const notInStorage = status === undefined || status === null || status === '';

  if (notInStorage) return true;
  else return status;
}

function getMemberInKeycloakData(action) {
  const memberInKeycloak = safe(() => action.payload.data.memberInKeycloak);
  const stateData = { memberInKeycloak };

  if (memberInKeycloak === undefined) stateData.memberInKeycloak = true;
  if (!stateData.memberInKeycloak) {
    stateData.temporaryPassword = getPrevAction(action).payload?.request?.data?.password;
  }

  sessionSetMemberInKeycloak(stateData.memberInKeycloak);
  return stateData;
}

const setSsoTokens = (auth = '', refresh = '') => {
  if (auth && refresh) {
    saveSsoTokens(auth, refresh);
  } else {
    clearSsoTokens();
  }
  return {
    auth,
    refresh
  };
};

const setAuthToken = (token = '') => {
  if (token) {
    saveAuthToken(token);
  } else {
    clearAuthToken();
  }
  return token;
};

function getAuthTokens(action) {
  if (action.type === 'keycloak/VERIFY_USER_SUCCESS') {
    const { token, refreshToken } = getAppObj().keycloak || {};
    return { ssoTokens: setSsoTokens(token, refreshToken), authToken: setAuthToken() };
  } else {
    const normalToken = parseAuthTokenFromApi(action?.payload?.headers?.['x-auth-token']);
    const meToken = parseAuthTokenFromApi(
      action?.payload?.config?.headers?.['x-auth-token']
    );
    return { authToken: setAuthToken(normalToken || meToken), ssoTokens: setSsoTokens() };
  }
}

function clearDataOnLogout(state) {
  sessionStorage.removeItem(STORAGE_KEY.USER);
  return {
    ...state,
    data: null,
    isRegisterIncompleteUser: false,
    profiles: null,
    authToken: setAuthToken(),
    ssoTokens: setSsoTokens()
  };
}

function getRegisterIncompleteUserStatus(userData) {
  return getIncompleteUserStatus(userData) || getAppliedUserStatus(userData);
}

const fetchingProps = {
  sendEmailFetching: false,
  loginFetching: false,
  externalLoginFetching: false,
  updatePasswordFetching: false,
  updateSettingsFetching: false,
  inviteCodeFetching: false,
  userDataUpdating: false,
  resetPasswordFetching: false,
  forgotPasswordFetching: false,
  companyIdFetching: false,
  sendContactUsMessageFetching: false,
  showRenaultFrancePopup: false,
  showSubscribeLoader: false
};

const creationSuccessDataDefaults = Object.freeze({
  email: '',
  firstName: ''
});

const getDefaultState = () => ({
  authToken: restoreAuthToken(),
  ssoTokens: restoreSsoTokens(),
  loginError: null,
  updateError: null,
  resetPasswordError: '',
  forgotPasswordError: '',
  externalLoginError: '',
  companyIdError: null,
  data: sessionStorageGetJson(STORAGE_KEY.USER),
  email: null,
  activationSuccess: false,
  alreadyActivated: false,
  activationExpired: false,
  sendEmailSuccess: false,
  inviteError: null,
  sendContactUsMessageError: '',
  company: false,
  companyBrand: false,
  registerCompany: null,
  brandCompanies: null,
  registerInnerStateData: null,
  creationSuccessData: creationSuccessDataDefaults,
  serviceRv: null,
  serviceB2b: null,
  settings: null,
  profiles: null,
  temporaryPassword: null,
  memberInKeycloak: sessionGetMemberInKeycloakIn(),
  isRegisterIncompleteUser: false,
  ...fetchingProps
});

export default function reducer(state = getDefaultState(), action) {
  switch (action.type) {
    case 'user/LOGIN': {
      state = { ...state, loginFetching: true };
      break;
    }

    case 'user/SET_AUTH_TOKEN': {
      state = {
        ...state,
        authToken: setAuthToken(action.token),
        ssoTokens: setSsoTokens()
      };
      break;
    }

    case 'user/SET_SSO_TOKENS': {
      const { tokens } = action;
      state = {
        ...state,
        ssoTokens: setSsoTokens(tokens.auth, tokens.refresh),
        authToken: setAuthToken()
      };
      break;
    }

    case 'user/SAVE_REGISTER_INNER_STATE_DATA': {
      state = { ...state, registerInnerStateData: action.data };
      break;
    }

    case 'user/CLEAR_REGISTER_INNER_STATE_DATA': {
      state = { ...state, registerInnerStateData: null };
      break;
    }

    case 'user/SAVE_CREATION_SUCCESS_DATA': {
      state = { ...state, creationSuccessData: { ...action.data } };
      break;
    }

    case 'user/CLEAR_CREATION_SUCCESS_DATA': {
      state = { ...state, creationSuccessData: creationSuccessDataDefaults };
      break;
    }

    case 'user/SET_REGISTER_INCOMPLETE_STATUS': {
      state = { ...state, isRegisterIncompleteUser: action.status };
      break;
    }

    case 'user/AUTO_UPDATE_REGISTER_INCOMPLETE_STATUS': {
      state = {
        ...state,
        isRegisterIncompleteUser: getRegisterIncompleteUserStatus(state.data)
      };
      break;
    }

    case 'user/SET_FLAG_TO_OPEN_RENAULT_FRANCE_POPUP': {
      state = { ...state, showRenaultFrancePopup: action.payload };
      break;
    }

    case 'user/SILENT_REFRESH_SUCCESS': {
      state = {
        ...state,
        authToken: setAuthToken(
          parseAuthTokenFromApi(action.payload.headers['x-auth-token'])
        ),
        ssoTokens: setSsoTokens()
      };
      break;
    }

    case 'user/LOGIN_SUCCESS':
    case 'user/REFRESH_SUCCESS':
    case 'keycloak/VERIFY_USER_SUCCESS': {
      const user = action?.payload?.data;
      const { updateIncompleteStatus = true } = getPrevAction(action);

      state = {
        ...state,
        data: user,
        loginError: null,
        loginFetching: false,
        ...getMemberInKeycloakData(action),
        ...getAuthTokens(action)
      };

      if (updateIncompleteStatus) {
        state = {
          ...state,
          isRegisterIncompleteUser: getRegisterIncompleteUserStatus(user)
        };
      }

      gtmPush(
        { event: 'Authentification', authentificationType: 'success' },
        _get(state, 'data.id')
      );

      break;
    }

    case 'user/UPDATE_MEMBER_IN_KEYCLOAK_STATUS': {
      sessionSetMemberInKeycloak(action.payload);
      state = { ...state, memberInKeycloak: action.payload };
      break;
    }

    case 'user/CLEAR_TEMPORARY_PASSWORD': {
      state = { ...state, temporaryPassword: null };
      break;
    }

    case 'user/GET_SETTINGS':
    case 'user/UPDATE_SETTINGS': {
      state = { ...state, updateSettingsFetching: true };
      break;
    }

    case 'user/UPDATE_SETTINGS_SUCCESS':
    case 'user/GET_SETTINGS_SUCCESS': {
      const settings = action.payload.data;
      state = { ...state, settings, updateSettingsFetching: false };
      break;
    }

    case 'user/UPDATE_SETTINGS_FAIL':
    case 'user/GET_SETTINGS_FAIL': {
      state = { ...state, updateSettingsFetching: false };
      break;
    }

    case 'user/LOGIN_FAIL': {
      let error = '';

      if (getErrorStatus(action) === 401) {
        error = 'error.login.401.unauthorized';
      } else if (getErrorStatus(action) === 422) {
        error = 'error.login.422.user.not.enabled';
      } else if (getErrorStatus(action) === 403) {
        error = 'error.login.403.user.anonymized';
      } else {
        error = 'error.form.technical';
      }
      state = { ...state, loginError: error, loginFetching: false };
      break;
    }

    case 'user/LOGOUT':
    case 'user/SELF_ANONYMIZE_SUCCESS': {
      state = clearDataOnLogout(state);
      if (action.redirect) browserHistory.push('/');
      break;
    }

    case 'user/REGISTER':
    case 'user/LINK_PROFILE':
    case 'user/REGISTER_SSO': {
      state = { ...state, userDataUpdating: true };
      break;
    }

    case 'user/REGISTER_SUCCESS':
    case 'user/LINK_PROFILE_SUCCESS':
    case 'user/REGISTER_SSO_SUCCESS': {
      state = { ...state, userDataUpdating: false };

      gtmPush({
        event: 'Registration',
        registrationType: 'success'
      });

      break;
    }

    case 'user/REGISTER_FAIL':
    case 'user/LINK_PROFILE_FAIL':
    case 'user/REGISTER_SSO_FAIL': {
      state = { ...state, userDataUpdating: false };
      break;
    }

    case 'user/FORCE_UPDATE_PASSWORD':
    case 'user/UPDATE_PASSWORD': {
      state = { ...state, updatePasswordFetching: true };
      break;
    }

    case 'user/FORCE_UPDATE_PASSWORD_SUCCESS':
    case 'user/UPDATE_PASSWORD_SUCCESS': {
      state = { ...state, updatePasswordFetching: false };
      break;
    }

    case 'user/FORCE_UPDATE_PASSWORD_FAIL':
    case 'user/UPDATE_PASSWORD_FAIL': {
      state = { ...state, updatePasswordFetching: false };
      break;
    }

    case 'user/FORGOT_PASSWORD': {
      state = { ...state, forgotPasswordFetching: true, forgotPasswordError: '' };
      break;
    }

    case 'user/FORGOT_PASSWORD_SUCCESS': {
      state = { ...state, forgotPasswordFetching: false, forgotPasswordError: '' };
      break;
    }

    case 'user/FORGOT_PASSWORD_FAIL': {
      const messages = messagesSelector(getGlobalState());
      let errorMsg = '';

      switch (getErrorStatus(action)) {
        case 404: {
          errorMsg = messages['error.forgot_password.404'];
          break;
        }

        default: {
          errorMsg = getErrorMsg({
            bundle: messages,
            error: getActionError(action),
            defKey: 'error.form.technical'
          });
        }
      }

      state = { ...state, forgotPasswordError: errorMsg, forgotPasswordFetching: false };
      break;
    }

    case 'user/CHECK_RESET_PASSWORD_TOKEN_FAIL': {
      const messages = messagesSelector(getGlobalState());

      const errorMsg = getErrorMsg({
        bundle: messages,
        error: getActionError(action),
        defKey: 'error.reset_password.401'
      });

      state = {
        ...state,
        resetPasswordError: errorMsg
      };

      break;
    }

    case 'user/RESET_PASSWORD': {
      state = { ...state, resetPasswordFetching: true };
      break;
    }
    case 'user/RESET_PASSWORD_SUCCESS': {
      state = { ...state, resetPasswordFetching: false };
      break;
    }

    case 'user/RESET_PASSWORD_FAIL': {
      const messages = messagesSelector(getGlobalState());
      let errorMsg = '';

      switch (getErrorStatus(action)) {
        case 400: {
          errorMsg = messages['error.form.password_confirmation.the_same']; // password issue
          break;
        }

        case 401: {
          errorMsg = messages['error.reset_password.401']; // token issue
          break;
        }

        default: {
          errorMsg = getErrorMsg({
            bundle: messages,
            error: getActionError(action)
          });
        }
      }

      state = { ...state, resetPasswordError: errorMsg, resetPasswordFetching: false };
      break;
    }

    case 'user/SET_SUBSCRIBE_LOADER_STATE': {
      state = { ...state, showSubscribeLoader: action.payload };
      break;
    }

    case 'user/FINAL_ACCESS_DENIED': {
      state = clearDataOnLogout(state);
      break;
    }

    case 'user/SEND_ACTIVATION_EMAIL': {
      state = { ...state, sendEmailFetching: true, sendEmailSuccess: false };
      break;
    }

    case 'user/SEND_ACTIVATION_EMAIL_SUCCESS': {
      state = { ...state, sendEmailSuccess: true, sendEmailFetching: false };
      break;
    }

    case 'user/SEND_ACTIVATION_EMAIL_FAIL': {
      if (typeof _get(action, 'error.response.data.developerMessage') === 'string') {
        const devMsgArray = getActionError(action).developerMessage.split(' ');
        const userActivated = _get(devMsgArray, '[3]') === 'already';
        const userNotFound = _get(devMsgArray, '[2]') === 'found';

        if (userNotFound || userActivated) state = { ...state, sendEmailSuccess: true };
      }

      state = { ...state, sendEmailFetching: false };
      break;
    }

    case 'user/ACCOUNT_ACTIVATION': {
      state = { ...state, alreadyActivated: false, activationExpired: false };
      break;
    }

    case 'user/ACCOUNT_ACTIVATION_SUCCESS': {
      state = {
        ...state,
        activationSuccess: true,
        alreadyActivated: false,
        activationExpired: false
      };
      break;
    }

    case 'user/ACCOUNT_ACTIVATION_FAIL': {
      if (_get(action, 'error.response.status') === 410) {
        state = { ...state, activationExpired: true };
      } else if (
        typeof _get(action, 'error.response.data.developerMessage') === 'string'
      ) {
        const devMsgArray = getActionError(action).developerMessage.split(' ');
        const userActivated = _get(devMsgArray, '[5]') === 'status';
        const status = _get(devMsgArray, '[6]');

        if (userActivated && status !== MEMBER_STATUS_APPLIED)
          state = { ...state, alreadyActivated: true };
      } else {
        addActionError(action);
      }

      break;
    }

    case 'user/REMOVE_LOGIN_ERRORS': {
      state = {
        ...state,
        loginError: '',
        forgotPasswordError: '',
        externalLoginError: ''
      };

      break;
    }

    case 'user/REMOVE_UPDATE_ERRORS': {
      state = { ...state, updateError: null };
      break;
    }

    case 'user/GET_INFO_SUCCESS': {
      let user = { ...action.payload.data };
      state = { ...state, data: user };
      break;
    }

    case 'keycloak/GET_REMOTE_CONFIG': {
      state = { ...state, externalLoginFetching: true };
      break;
    }

    case 'user/SET_EXTERNAL_LOGIN_FETCHING': {
      state = { ...state, externalLoginFetching: action.payload };
      break;
    }

    case 'keycloak/GET_REMOTE_CONFIG_SUCCESS': {
      state = { ...state, externalLoginError: '' };
      break;
    }

    case 'keycloak/GET_REMOTE_CONFIG_FAIL': {
      const globalState = getGlobalState();
      const messages = messagesSelector(globalState);

      state = {
        ...state,
        externalLoginFetching: false,
        externalLoginError: t(messages, 'get.sso.config.fail')
      };

      break;
    }

    case 'user/GET_INFO_FAIL': {
      const { silent } = getPrevAction(action);
      if (!silent) addActionError(action);
      break;
    }

    case 'user/UPDATE_INFO': {
      state = { ...state, userDataUpdating: true };
      break;
    }

    case 'user/UPDATE_INFO_SUCCESS': {
      const user = action.payload.data;
      state = { ...state, data: user, userDataUpdating: false };
      break;
    }

    case 'user/UPDATE_INFO_FAIL': {
      state = {
        ...state,
        userDataUpdating: false
      };

      break;
    }

    case 'user/SEND_CONTACT_US_MESSAGE': {
      state = {
        ...state,
        sendContactUsMessageFetching: true,
        sendContactUsMessageError: ''
      };
      break;
    }

    case 'user/SEND_CONTACT_US_MESSAGE_SUCCESS': {
      state = {
        ...state,
        sendContactUsMessageFetching: false,
        sendContactUsMessageError: ''
      };
      gtmPush({ event: 'Contact request' }, _get(state, 'data.id'));
      break;
    }

    case 'user/SEND_CONTACT_US_MESSAGE_FAIL': {
      const messages = messagesSelector(getGlobalState());

      state = {
        ...state,
        sendContactUsMessageError: getErrorMsg({
          bundle: messages,
          error: getActionError(action)
        }),
        sendContactUsMessageFetching: false
      };

      break;
    }

    case 'user/GET_FILE_INFO_SUCCESS': {
      break;
    }

    case 'user/GET_COMPANY_ID': {
      state = { ...state, companyIdFetching: true, companyIdError: null };
      break;
    }

    case 'user/GET_COMPANY_ID_FAIL': {
      state = { ...state, companyIdFetching: false, companyIdError: true };
      break;
    }

    case 'user/GET_COMPANY_ID_SUCCESS': {
      state = { ...state, companyIdFetching: false };
      break;
    }

    case 'user/GET_COMPANY_SUCCESS': {
      state = { ...state, company: action.payload.data };
      break;
    }

    case 'user/CHECK_INVITE_CODE': {
      state = { ...state, inviteCodeFetching: true, inviteError: null };
      break;
    }

    case 'user/CHECK_INVITE_CODE_FAIL': {
      const resp = action.error || {};
      const inviteError = resp.response.data || {};

      state = {
        ...state,
        inviteError,
        inviteCodeFetching: false
      };

      break;
    }

    case 'user/CHECK_INVITE_CODE_ERROR': {
      state = {
        ...state,
        inviteError: action.error,
        inviteCodeFetching: false
      };
      break;
    }

    case 'user/CHECK_INVITE_CODE_SUCCESS': {
      const { keepFetching } = getPrevAction(action);
      state = { ...state, inviteCodeFetching: keepFetching };
      break;
    }

    case 'user/CHECK_INVITE_CODE_FETCH_DONE': {
      state = { ...state, inviteCodeFetching: false };
      break;
    }

    case 'user/CHECK_INVITE_CODE_CLEAN_ERROR': {
      state = {
        ...state,
        inviteError: null
      };
      break;
    }

    case 'user/GET_REGISTER_COMPANY_SUCCESS': {
      state = { ...state, registerCompany: action.payload.data };
      break;
    }

    case 'user/GET_BRAND_COMPANIES': {
      state = { ...state, brandCompanies: null };
      break;
    }

    case 'user/GET_BRAND_COMPANIES_SUCCESS': {
      const found = safe(() => action.payload.data.length);

      if (found) {
        state = { ...state, brandCompanies: action.payload.data };
      }
      break;
    }

    case 'user/GET_REGISTER_COMPANY_FAIL': {
      addActionError(action);
      break;
    }

    case 'user/GET_COMPANY_FOR_BRAND_SUCCESS': {
      state = { ...state, companyBrand: action.payload.data };
      break;
    }

    case 'user/CLEAR_REGISTER_COMPANY_DATA': {
      state = { ...state, registerCompany: null };
      break;
    }
    case 'user/SUBSCRIBE_VIEW_RV': {
      state = { ...state, serviceRv: true };
      break;
    }
    case 'user/SUBSCRIBE_VIEW_B2B': {
      state = { ...state, serviceB2b: true };
      break;
    }
    case 'user/SUBSCRIBE_VIEW_RESET': {
      state = { ...state, serviceB2b: null, serviceRv: null };
      break;
    }

    case 'user/GET_PROFILES_SUCCESS': {
      state = { ...state, profiles: action.payload.data };
      break;
    }

    case 'user/GET_PROFILES_FAIL': {
      addActionError(action);
      state = { ...state, profiles: null };
      break;
    }

    default: {
      break;
    }
  }

  return state;
}
