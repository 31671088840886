import React from 'react';

import {
  getMessage,
  findUserCountry,
  strToBool,
  getAppObj,
  addQuery
} from '../utils/utils';

import IndexLink from '../components/partials/IndexLink';
import _find from 'lodash/find';
import { CUSTOM_FIELD_STATUS_CONDITIONED, CUSTOM_FIELD_STATUS_YES } from '../constants/backend';
import { showErrorInfo } from '../components/forms/newFields';
import { addErrorMsg, addSuccessMsg } from '../utils/flashMessage/creator';
import { creation as cr } from '../constants/routes';
import { getErrorMsg } from './errors';

const aStyle = {
  cursor: 'pointer'
};

export function getFaqLink({ label, userCompanyId, action, linkProps, mapCountries }) {
  const { activeClassName, ...urlProps } = linkProps;

  let infosUrl = '';

  if (userCompanyId) {
    const userCountry = findUserCountry(mapCountries, userCompanyId);
    const { infosUrl: urlBySearch } = userCountry || {};

    // user logged in, company related faq found (Save external url)
    infosUrl = urlBySearch;
  }

  if (!infosUrl && mapCountries) {
    const countryKeys = Object.keys(mapCountries);
    const firstKey = countryKeys[0];

    // one contry object (Save external url)
    if (countryKeys.length === 1) {
      infosUrl = mapCountries[firstKey].infosUrl;
    }

    // multiple countries (Open modal)
    if (countryKeys.length > 1) {
      return (
        <a
          style={aStyle}
          onClick={action}
          className={linkProps && linkProps.className}
          role="button"
        >
          {label}
        </a>
      );
    }
  }

  // external url found (Direct click open new tab)
  if (infosUrl) {
    return (
      <a target="_blank" href={infosUrl} {...urlProps}>
        {label}
      </a>
    );
  }

  // default case (Internal Faq)
  return (
    <IndexLink activeClassName={activeClassName} {...urlProps} to="/faq">
      {label}
    </IndexLink>
  );
}

function fieldTouched(fieldName, fieldsMeta = {}) {
  const { [fieldName]: fieldData } = fieldsMeta;
  const { touched } = fieldData || {};
  return touched;
}

function fieldError(fieldName, submitErrors) {
  return submitErrors && submitErrors[fieldName];
}

export function getErrorInfoExternal(fieldName, props = {}) {
  const { submitErrors, fieldsMeta } = props;

  return showErrorInfo({
    meta: {
      touched: fieldTouched(fieldName, fieldsMeta),
      error: fieldError(fieldName, submitErrors)
    }
  });
}

export function documentPresent(fileData = {}) {
  return fileData.fileId;
}

export function getLabelFromOptions(options, toFindOption) {
  const predicate = (option = {}) => option.value === toFindOption;
  const found = _find(options, predicate);
  return found ? found.title : '-';
}

export function customFieldIsVisible(field, fieldValues, fieldIdsByPosition) {
  const { companyCustomField } = field || {};
  const { visible, visibleCondition } = companyCustomField || {};
  const { dependsOnPosition, expectedValueForPosition } = visibleCondition || {};
  if (visible === CUSTOM_FIELD_STATUS_CONDITIONED) {
    const parentId = fieldIdsByPosition[dependsOnPosition];
    const parentValue = !!fieldValues[parentId];

    return parentValue === strToBool(expectedValueForPosition);
  } else return true;
}

export function displayProfileUpdateSuccess(messages, sectionTitleKey) {
  const sectionName = getMessage(messages, sectionTitleKey);
  const updateWord = getMessage(messages, 'generic.updated').toLowerCase();
  const message = sectionName + ' ' + updateWord;
  addSuccessMsg(message);
}

export function showTOU(company = {}) {
  const { termsOfUseUrl } = company;
  return termsOfUseUrl;
}

export function showTOS(company = {}) {
  const { termsOfSubscriptionUrl } = company;
  return termsOfSubscriptionUrl;
}

export function showPrivacyPolicy(company = {}) {
  const { privacyPolicyUrl } = company;
  return privacyPolicyUrl;
}

export function createElement(type, ...classList) {
  const element = document.createElement(type);
  if (classList.length) element.classList.add(...classList);
  return element;
}

export const routeNavigate = (to, options, times = 0) => {
  const { navigate } = getAppObj();
  if (navigate) navigate(to, options);
  else if (times < 5) {
    setTimeout(() => routeNavigate(to, options, times + 1), 300);
  }
};

export const historyToNavigate = (data, replace = false) => {
  if (typeof data === 'string') {
    routeNavigate(data, { replace });
  } else {
    const { pathname, query } = data;
    routeNavigate(pathname + addQuery(query), { replace });
  }
};

export const browserHistory = {
  push: (data) => historyToNavigate(data, false),
  replace: (data) => historyToNavigate(data, true)
};

export function setNavigate(navigate) {
  getAppObj().navigate = navigate;
}

export function getCreationId(routeParams) {
  return routeParams?.[cr.id];
}

export function showApiError(m, data) {
  addErrorMsg(
    getErrorMsg({
      bundle: m,
      error: ((data.error || {}).response || {}).data
    })
  );
}
