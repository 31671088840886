import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import keyIconSrc from '../../../src/assets/images/key-circle.svg';
import { profileTextField } from '../forms/newFields';
import { getMessage as t } from '../../utils/utils';
import { messagesSelector, brandIdSelector } from '../../helpers/selectors';

import { connect } from 'react-redux';
import { USER_FORM_NAMES } from '../../constants/form';
import { emailAsyncValidate } from '../../helpers/api';
import { checkEmail, checkPassword } from '../../helpers';

const redColorStyle = {
  color: '#e61919'
};

const asyncValidate = ({ email }, dispatch, props) => {
  return emailAsyncValidate({ email }, props.m, props.brandId);
};

const validate = (values, props) => {
  const errors = {};
  const { m } = props;

  const emailError = checkEmail(values.email, m);
  const passError = checkPassword(values.password, m);

  if (emailError) errors.email = emailError;
  if (passError) errors.password = passError;

  return errors;
};

function getErrorStyle(meta) {
  const { touched, error } = meta || {};
  return touched && error ? redColorStyle : undefined;
}

function isError(meta) {
  const { touched, error } = meta || {};
  return touched && error;
}

function passwordField({ m, meta, ...props }) {
  return (
    <div className="scope-field-container">
      <div className="scope-field-label">{t(m, 'label.password')} *</div>
      {profileTextField({
        placeholder: t(m, 'label.password'),
        type: 'password',
        ...props
      })}
      <div className="scope-field-info" style={getErrorStyle(meta)}>
        {t(m, 'register.field.info.password')}
      </div>
    </div>
  );
}

function getErrorInfo(meta) {
  if (isError(meta)) {
    return (
      <div className="scope-field-info" style={getErrorStyle(meta)}>
        {meta.error}
      </div>
    );
  }
}

function emailField({ m, meta, ...props }) {
  return (
    <div className="scope-field-container">
      <div className="scope-field-label">{t(m, 'generic.email')} *</div>
      {profileTextField({
        placeholder: t(m, 'generic.email'),
        ...props
      })}
      {getErrorInfo(meta)}
    </div>
  );
}

class RegisterCredentials extends Component {
  render() {
    const { handleSubmit, m } = this.props;

    return (
      <form className="account-credentials-page" onSubmit={handleSubmit} noValidate>
        <div className="scope-content-header">
          <img className="scope-content-header-picture" src={keyIconSrc} alt="key icon" />
          <div className="scope-content-header-title-container">
            <div className="scope-content-header-title">
              {t(m, 'profile.section.credentials')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-fields">
              <Field name="email" m={m} component={emailField} />
              <Field name="password" m={m} component={passwordField} />
            </div>
          </div>
        </div>
        <div className="scope-content-footer">
          <button type="submit" className="scope-submit-button primary-btn">
            {t(m, 'generic.next')}
          </button>
        </div>
      </form>
    );
  }
}

const reduxComponent = reduxForm({
  form: USER_FORM_NAMES.REGISTER_CREDENTIALS,
  fields: ['email', 'password'],
  asyncBlurFields: ['email'],
  validate,
  asyncValidate
})(RegisterCredentials);

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    brandId: brandIdSelector(state)
  };
}

export default connect(mapStateToProps)(reduxComponent);
