import { browserHistory } from '../helpers/components';
import { change } from 'redux-form';
import { USAGE_TYPE_BUSINESS, USAGE_TYPE_PRIVATE } from '../constants/backend';
import _isString from 'lodash/isString';
import endpoints from '../constants/endpoints';

import {
  popup_Generic_SetVisibility,
  popup_BookingRecap_SetVisibility,
  popup_Login_SetVisibility
} from './popup';

import { destroy } from 'redux-form';
import { USER_FORM_NAMES } from '../constants/form';
import {
  localeSelector,
  messagesSelector,
  userIdSelector,
  userCompanyIdSelector,
  brandIdSelector,
  anyAuthTokenSelector
} from '../helpers/selectors';

import {
  getMessage as t,
  navigateToWelcomePage,
  isSubscribePage,
  isRMF_companyId,
  isBookingPage,
  isHomePage,
  safe,
  addQuery,
  isCreationPage,
  navigateToCreationPage,
  parseAuthTokenFromApi
} from './../utils/utils';

import { ONE_WAY } from '../constants/generic';
import { getPreBookingStatus, search } from './booking';
import _forEach from 'lodash/forEach';
import { formatDataForSearchBookingsApiCall, setMomentTimeForRequest } from '../helpers';
import { trimStrings } from '../utils/utils';
import { addErrorMsg } from '../utils/flashMessage/creator';

function handleUpdateBookingSearch(userData) {
  return (dispatch, getState) => {
    if (!(isHomePage() || isBookingPage())) return;

    const state = getState();
    const { company } = userData || {};
    const { contract, id: companyId } = company || {};
    const { businessCarSharing, privateCarSharing, interfaceConfig } = contract || {};
    const { returnTripDisplay } = interfaceConfig || {};
    const { values: searchValues } = state.form.searchBookings || {};
    const { usageTypes, endDate } = searchValues || {};
    const isRecapPopupVisible = state.popup.type_BookingRecap_IsVisible;
    const countryISO = safe(() => searchValues.location.countryISO);
    const mapCountries = safe(() => state.brand.branded.mapCountries) || {};
    const remoteCompany = mapCountries[countryISO] || {};
    const remoteCompanyId = remoteCompany.companyId;

    if (searchValues) {
      if (!returnTripDisplay && _isString(endDate)) {
        dispatch(change('searchBookings', 'endDate', null));
        dispatch(change('searchBookings', 'endTime', null));
        dispatch(change('bookingType', 'tripType', ONE_WAY));
      }

      if (!businessCarSharing && usageTypes === USAGE_TYPE_BUSINESS) {
        dispatch(change('bookingType', 'usageTypes', USAGE_TYPE_PRIVATE));
        dispatch(change('searchBookings', 'usageTypes', USAGE_TYPE_PRIVATE));
      }

      if (!privateCarSharing && usageTypes === USAGE_TYPE_PRIVATE) {
        dispatch(change('bookingType', 'usageTypes', USAGE_TYPE_BUSINESS));
        dispatch(change('searchBookings', 'usageTypes', USAGE_TYPE_BUSINESS));
      }

      if (countryISO && companyId && companyId !== remoteCompanyId) {
        const formattedData = formatDataForSearchBookingsApiCall(
          setMomentTimeForRequest(searchValues),
          companyId,
          contract
        );

        dispatch(search(formattedData));
      }

      if (isRecapPopupVisible) {
        dispatch(popup_BookingRecap_SetVisibility(false));
      }
    }
  };
}

function handleNoContract(msgKey) {
  return (dispatch, getState) => {
    const m = getState().i18n.messages;
    const genericPopupText = t(m, msgKey);

    dispatch(popup_Generic_SetVisibility(true, { genericPopupText }));
    dispatch(logout(true));
  };
}

function handleCompanyContract(userData) {
  return (dispatch) => {
    const { company } = userData || {};
    const { contract } = company || {};
    const { businessCarSharing, privateCarSharing } = contract || {};

    if (!contract) {
      dispatch(handleNoContract('error.contract.not-found'));
      return;
    }

    if (!businessCarSharing && !privateCarSharing) {
      dispatch(handleNoContract('error.contract.inactive'));
      return;
    }

    dispatch(handleUpdateBookingSearch(userData));
  };
}

export function handleLoginSuccess(resp) {
  return (dispatch, getState) => {
    const state = getState();
    const data = resp.payload.data || {};
    const isLoginPopupVisible = state.popup.type_Login_IsVisible;

    dispatch(handleCompanyContract(data));

    if (isLoginPopupVisible) {
      dispatch(popup_Login_SetVisibility(false));
    }
  };
}

export function login(rawData, updateIncompleteStatus = true) {
  return (dispatch, getState) => {
    const credentials = trimStrings(rawData);

    credentials.brandId = brandIdSelector(getState());

    return dispatch({
      type: 'user/LOGIN',
      updateIncompleteStatus,
      payload: {
        request: {
          method: 'post',
          url: endpoints.authenticate,
          data: credentials
        }
      }
    }).then((resp) => {
      if (resp.type === 'user/LOGIN_SUCCESS') {
        dispatch(handleLoginSuccess(resp));
      }
      return resp;
    });
  };
}

// noinspection JSUnusedGlobalSymbols
export function removeLoginErrors() {
  return {
    type: 'user/REMOVE_LOGIN_ERRORS'
  };
}

export function logout(redirect = true) {
  return {
    type: 'user/LOGOUT',
    redirect
  };
}

// noinspection JSUnusedGlobalSymbols
export function updateUserPassword(data) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/UPDATE_PASSWORD',
      payload: {
        request: {
          method: 'put',
          url: '/v2/users/password',
          data: {
            newPassword: data.newPassword,
            oldPassword: data.oldPassword
          }
        }
      }
    });
  };
}

export function forceUpdateUserPassword(data) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/FORCE_UPDATE_PASSWORD',
      payload: {
        request: {
          method: 'put',
          url: '/v2/users/password/force',
          data: {
            newPassword: data.newPassword,
            oldPassword: data.oldPassword
          }
        }
      }
    });
  };
}

export function removeUpdateErrors() {
  return {
    type: 'user/REMOVE_UPDATE_ERRORS'
  };
}

export function register(userData) {
  return {
    type: 'user/REGISTER',
    payload: {
      request: {
        method: 'post',
        url: '/v2/members',
        data: userData
      }
    }
  };
}

export function registerExternalUser(userData) {
  return {
    type: 'user/REGISTER_SSO',
    payload: {
      request: {
        method: 'post',
        url: '/v2/members/sso',
        data: userData
      }
    }
  };
}

export function sendForgotPasswordEmail(data) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/FORGOT_PASSWORD',
      payload: {
        request: {
          method: 'post',
          url:
            endpoints.passwordReset +
            '?login=' +
            encodeURIComponent(data.login) +
            '&brandId=' +
            brandIdSelector(getState())
        }
      }
    });
  };
}

export function checkResetPasswordToken(token) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/CHECK_RESET_PASSWORD_TOKEN',
      payload: {
        request: {
          method: 'get',
          url:
            endpoints.passwordReset +
            '?token=' +
            encodeURIComponent(token) +
            '&brandId=' +
            brandIdSelector(getState())
        }
      }
    });
  };
}

export function resetPassword(data, token) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/RESET_PASSWORD',
      payload: {
        request: {
          method: 'put',
          url: endpoints.passwordReset + '?token=' + encodeURIComponent(token),
          data: data
        }
      }
    });
  };
}

export function redirectIfNotAuthenticated({ pathname }) {
  return (dispatch, getState) => {
    const state = getState();
    const messages = messagesSelector(state);

    if (!anyAuthTokenSelector(state) && !state.popup.type_Login_IsVisible) {
      browserHistory.push({ pathname: '/', query: { modal: 'login-popup' } });
      addErrorMsg(messages['error.session.expired']);
    }

    return dispatch({
      type: 'user/FIRST_ACCESS_DENIED',
      payload: pathname
    });
  };
}

export function handleSessionExpired() {
  return (dispatch, getState) => {
    const state = getState();
    const messages = messagesSelector(state);

    if (!state.popup.type_Login_IsVisible) {
      browserHistory.push({ pathname: '/', query: { modal: 'login-popup' } });
      addErrorMsg(messages['error.session.expired']);
    }

    return dispatch({ type: 'user/FINAL_ACCESS_DENIED' });
  };
}

export function sendActivationEmail(login) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/SEND_ACTIVATION_EMAIL',
      payload: {
        request: {
          method: 'post',
          url:
            '/v2/users/validate?login=' +
            encodeURIComponent(login) +
            '&brandId=' +
            brandIdSelector(getState())
        }
      }
    });
  };
}

// noinspection JSUnusedGlobalSymbols
export function accountActivation(token) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/ACCOUNT_ACTIVATION',
      payload: {
        request: {
          method: 'put',
          url: '/v2/users/validate?subscriptionToken=' + encodeURIComponent(token)
        }
      }
    });
  };
}

export function getUserInfo(silent) {
  return {
    silent,
    type: 'user/GET_INFO',
    payload: {
      request: {
        method: 'get',
        url: endpoints.me
      }
    }
  };
}

export function getUserSettings(userId) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/GET_SETTINGS',
      payload: {
        request: {
          method: 'get',
          url: `/v2/members/${userId}/settings`
        }
      }
    });
  };
}

// noinspection JSUnusedGlobalSymbols
export function updateUserSettings(userId, data) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/UPDATE_SETTINGS',
      payload: {
        request: {
          method: 'put',
          url: `/v2/members/${userId}/settings`,
          data
        }
      }
    });
  };
}

export function updateInformations(userId, data) {
  return (dispatch, getState) => {
    const state = getState();

    if (!data.locale) data = { ...data, locale: localeSelector(state) };

    return dispatch({
      type: 'user/UPDATE_INFO',
      payload: {
        request: {
          method: 'put',
          url: '/v2/members/' + encodeURIComponent(userId),
          data
        }
      }
    })
      .then((data) => data)
      .catch((error) => error);
  };
}

export function linkUserProfile(data) {
  return (dispatch, getState) => {
    const state = getState();

    if (!data.locale) data = { ...data, locale: localeSelector(state) };

    return dispatch({
      type: 'user/LINK_PROFILE',
      payload: {
        request: {
          method: 'post',
          url: '/v2/members/me/profiles',
          data
        }
      }
    })
      .then((data) => data)
      .catch((error) => error);
  };
}

export function switchUserProfile(profileId) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/SWITCH_PROFILE',
      payload: {
        request: {
          method: 'post',
          url: `/v2/members/${profileId}/activate`
        }
      }
    })
      .then((data) => data)
      .catch((error) => error);
  };
}

export function sendConfirmationEmail(data) {
  return {
    type: 'user/SEND_CONFIRMATION_EMAIL',
    payload: {
      request: {
        method: 'post',
        url: `/v2/users/emails/confirmation${addQuery(data)}`
      }
    }
  };
}

export function selfAnonymize(redirect = true) {
  return {
    redirect,
    type: 'user/SELF_ANONYMIZE',
    payload: {
      request: {
        method: 'post',
        url: `/v2/members/me/request-anonymization`
      }
    }
  };
}

export function sendContactUsMessage(data) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/SEND_CONTACT_US_MESSAGE',
      payload: {
        request: {
          method: 'post',
          url: '/v2/contact/member',
          data: data
        }
      }
    });
  };
}

// noinspection JSUnusedGlobalSymbols
export function setRegisterUserData(data) {
  return {
    type: 'user/SET_REGISTER_USER_DATA',
    payload: data
  };
}

export function autoUpdateRegisterIncompleteStatus() {
  return {
    type: 'user/AUTO_UPDATE_REGISTER_INCOMPLETE_STATUS'
  };
}

export function setRegisterIncompleteStatus(status) {
  return {
    type: 'user/SET_REGISTER_INCOMPLETE_STATUS',
    status
  };
}

export function getRegisterCompany(id) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/GET_REGISTER_COMPANY',
      payload: {
        request: {
          method: 'get',
          url: '/v2/companies/' + id
        }
      },
      companyId: id
    });
  };
}

export function getBrandCompanies(brandId) {
  return (dispatch) => {
    return dispatch({
      type: 'user/GET_BRAND_COMPANIES',
      payload: {
        request: {
          method: 'get',
          url: `/v2/brands/${brandId}/super-companies/public`
        }
      }
    });
  };
}

export function getCompany(id) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/GET_COMPANY_FOR_BRAND',
      payload: {
        request: {
          method: 'get',
          url: '/v2/companies/' + id
        }
      },
      companyId: id
    });
  };
}

function getUserAfterRefresh(token) {
  return (dispatch) => {
    return dispatch({
      type: 'user/REFRESH',
      payload: {
        request: {
          method: 'get',
          headers: { 'x-auth-token': token },
          url: endpoints.me
        }
      }
    });
  };
}

function getRefreshToken(token) {
  return {
    type: 'user/NORMAL_REFRESH',
    payload: {
      request: {
        method: 'post',
        headers: { 'x-auth-token': token },
        url: endpoints.refresh
      }
    }
  };
}

export function loginUserWithToken(token) {
  return (dispatch) => {
    return dispatch(getRefreshToken(token)).then((data) => {
      if (data.type === 'user/NORMAL_REFRESH_SUCCESS') {
        const newToken = parseAuthTokenFromApi(data.payload.headers['x-auth-token']);
        return dispatch(getUserAfterRefresh(newToken));
      } else {
        return data;
      }
    });
  };
}

export function updateTokenSilent(token) {
  return {
    type: 'user/SILENT_REFRESH',
    payload: {
      request: {
        method: 'post',
        headers: { 'x-auth-token': token },
        url: endpoints.refresh
      }
    }
  };
}

export function saveRegisterInnerStateData(data) {
  return {
    type: 'user/SAVE_REGISTER_INNER_STATE_DATA',
    data
  };
}

export function clearRegisterInnerStateData() {
  return {
    type: 'user/CLEAR_REGISTER_INNER_STATE_DATA'
  };
}

export function saveCreationSuccessData(data) {
  return {
    type: 'user/SAVE_CREATION_SUCCESS_DATA',
    data
  };
}

export function clearCreationSuccessData() {
  return {
    type: 'user/CLEAR_CREATION_SUCCESS_DATA'
  };
}

export function clearRegisterCompanyData() {
  return {
    type: 'user/CLEAR_REGISTER_COMPANY_DATA'
  };
}

export function clearProfileFormData() {
  return (dispatch) => {
    _forEach(USER_FORM_NAMES, (formName) => dispatch(destroy(formName)));
  };
}

export function checkInviteCode(shortId, keepFetching) {
  return {
    type: 'user/CHECK_INVITE_CODE',
    keepFetching,
    payload: {
      request: {
        method: 'get',
        url: '/v2/companies/invitation/' + shortId
      }
    }
  };
}

export function checkInviteCodeStopFetch() {
  return {
    type: 'user/CHECK_INVITE_CODE_FETCH_DONE'
  };
}

export function addInvitError(error) {
  return {
    type: 'user/CHECK_INVITE_CODE_ERROR',
    error
  };
}

export function removeInviteError() {
  return {
    type: 'user/CHECK_INVITE_CODE_CLEAN_ERROR'
  };
}

export function displayRV() {
  return { type: 'user/SUBSCRIBE_VIEW_RV' };
}

export function displayB2B() {
  return { type: 'user/SUBSCRIBE_VIEW_B2B' };
}

export function resetSubscribeDisplay() {
  return { type: 'user/SUBSCRIBE_VIEW_RESET' };
}

export function setFlagToOpenRenaultFrancePopup(flag) {
  return { type: 'user/SET_FLAG_TO_OPEN_RENAULT_FRANCE_POPUP', payload: flag };
}

export function setExternalLoginFetching(status) {
  return { type: 'user/SET_EXTERNAL_LOGIN_FETCHING', payload: status };
}

export function getUserProfiles() {
  return (dispatch, getState) => {
    return dispatch({
      type: 'user/GET_PROFILES',
      payload: {
        request: {
          method: 'get',
          url: '/v2/members/me/profiles'
        }
      }
    });
  };
}

export function handleIncompleteUserRedirect(userData) {
  return (dispatch, getState) => {
    const state = getState();
    const { id: userId = userIdSelector(state), company } = userData || {};
    const { id: userCompanyId = userCompanyIdSelector(state) } = company || {};

    if (!isSubscribePage() && !isCreationPage() && !isRMF_companyId(userCompanyId)) {
      dispatch(getPreBookingStatus(userId)).then((status) => {
        if (status) {
          navigateToWelcomePage();
        } else {
          navigateToCreationPage(userCompanyId);
        }
      });
    }
  };
}

export function clearTemporaryPassword() {
  return {
    type: 'user/CLEAR_TEMPORARY_PASSWORD'
  };
}

export function updateMemberInKeycloakStatus(status) {
  return {
    type: 'user/UPDATE_MEMBER_IN_KEYCLOAK_STATUS',
    payload: status
  };
}

export function setSubscribeLoaderState(payload) {
  return {
    type: 'user/SET_SUBSCRIBE_LOADER_STATE',
    payload
  };
}

export function setAuthToken(token = '') {
  return {
    type: 'user/SET_AUTH_TOKEN',
    token
  };
}

export function setSsoTokens({ auth = '', refresh = '' } = {}) {
  return {
    type: 'user/SET_SSO_TOKENS',
    tokens: { auth, refresh }
  };
}
