import { Component } from 'react';
import { browserHistory } from '../helpers/components';
import { getObjectFromQuery, setDeepRouterQuery } from '../helpers';
import { jsonParseSafe, removeQueryFromUrl } from '../utils/utils';
import { WORLDPAY_PAYMENT_AUTHORIZED } from '../constants/backend';

import { STORAGE_KEY, STORAGE_ENUM } from '../constants/generic';

const redirectPaths = {
  '/mes-reservations': '/my-bookings',
  '/contactez-nous': '/contact-us',
  '/create-booking': '/book',
  '/reserver': '/book',
  '/index.html': '/'
};

class Redirect extends Component {
  componentDidMount() {
    this.parseReturnFromWorldpay();
    this.handleRedirect();
  }

  handleRedirect() {
    const path = redirectPaths[window.location.pathname];
    const query = window.location.search;

    if (!path) return;

    if (query) {
      return browserHistory.replace({
        pathname: '/book',
        query: setDeepRouterQuery(getObjectFromQuery(query))
      });
    }

    browserHistory.replace(path);
  }

  parseReturnFromWorldpay() {
    const { orderKey, paymentStatus } = getObjectFromQuery() || {};
    const searchDataEncoded = localStorage.getItem(STORAGE_KEY.SEARCH_DATA);
    const worldpayRedirect = localStorage.getItem(STORAGE_KEY.BOOKING_WORLDPAY);
    const searchData = jsonParseSafe(searchDataEncoded);
    const orderCode = orderKey ? orderKey.split('^').pop() : '';

    if (orderKey || worldpayRedirect) {
      removeQueryFromUrl();

      if (orderCode) {
        if (paymentStatus === WORLDPAY_PAYMENT_AUTHORIZED) {
          localStorage.setItem(STORAGE_KEY.ORDER_CODE, orderCode);
        } else if (paymentStatus) {
          localStorage.setItem(STORAGE_KEY.ORDER_CODE, STORAGE_ENUM.BAD_ORDER_CODE);
        }
      }
      localStorage.removeItem(STORAGE_KEY.BOOKING_WORLDPAY);

      if (searchData) {
        browserHistory.replace({
          pathname: '/book',
          query: setDeepRouterQuery(searchData)
        });
      } else {
        browserHistory.replace('/book');
      }
    }
  }

  render() {
    return null;
  }
}

export default Redirect;
