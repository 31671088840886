import React, { Component } from 'react';
import { browserHistory } from '../helpers/components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import { change } from 'redux-form';
import * as BookingActions from '../actions/booking';
import * as PopupActions from '../actions/popup';
import * as UserActions from '../actions/user';
import { getUsageFromContract, setDeepRouterQuery } from '../helpers';

import SearchBookingsHomepageForm from './forms/SearchBookingsHomepageForm';
import { gtmPush, navigateToCreationPage, safe } from '../utils/utils';
import InviteCodeForm from './forms/InviteCodeForm';
import BusinessTabs from './BusinessTabs';
import LoginMsgForm from './forms/LoginMsgForm';
import { brandKeycloakSelector } from '../helpers/selectors';
import OnlySsoForm from './forms/OnlySsoForm';
import { useMatch } from 'react-router-dom';
import { validateAccount } from '../constants/routes';

export class Homepage extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.searchBookings = (formData) => {
      browserHistory.push({ pathname: '/book', query: setDeepRouterQuery(formData) });
    };

    this.handleInviteSubmit = (data) => {
      this.props.checkInviteCode(data.inviteCode).then((data) => {
        if (data.type === 'user/CHECK_INVITE_CODE_SUCCESS') {
          const companyId = data.payload.data.id;
          this.props.closeAllPopups();
          navigateToCreationPage(companyId);
        }
      });
    };
  }

  handleRenaultFranceUser() {
    const { showRenaultFrancePopup } = this.props.user;

    if (showRenaultFrancePopup) {
      this.props.setFlagToOpenRenaultFrancePopup(false);
      this.props.popup_Login_SetVisibility(true);
      this.props.popupStep_Subscribe_SetVisibility(true);
    }
  }

  componentDidMount() {
    this.handleGtm();
    this.componentPropsUpdated();
  }

  componentDidUpdate(pp) {
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp) {
    this.handleActivationToken();
    this.handleRenaultFranceUser();
    this.updateUsageTypesField(pp);
  }

  contractUpdated(pp) {
    const { contract: next } = safe(() => this.props.brand.branded) || {};
    const { contract: prev } = safe(() => pp.brand.branded) || {};
    return next !== prev || !pp;
  }

  updateUsageTypesField(pp) {
    if (this.contractUpdated(pp)) {
      const { contract } = safe(() => this.props.brand.branded);
      const usage = getUsageFromContract(contract);
      this.props.dispatch(change('searchBookings', 'usageTypes', usage));
    }
  }

  handleGtm() {
    gtmPush(
      {
        event: '_virtualPageview',
        pageName: 'mobility|homepage'
      },
      _get(this.props, 'user.data.id')
    );
  }

  handleActivationToken() {
    const { token } = this.props.routeParams;
    const { accountActivation, onValidateAccountPage } = this.props;

    if (onValidateAccountPage && token && !this.accountActivationDisplayed) {
      this.accountActivationDisplayed = true;
      accountActivation(token).then(() => {
        browserHistory.push({
          pathname: '/',
          query: { modal: 'account-activation-popup' }
        });
      });
    }
  }

  showMainContent() {
    const { useOnlySsoForLogin } = this.props.brandKeycloak || {};
    const { branded } = this.props.brand || {};
    const contract = branded.contract || {};
    const isRV = safe(() => contract.interfaceConfig.replacementVehicleDisplay);
    const { companyIdError, data: authUser } = this.props.user || {};

    if (!authUser && useOnlySsoForLogin) {
      return <OnlySsoForm />;
    }

    if ((!branded.restrictedAccess || authUser) && !isRV) {
      return (
        <div>
          {!authUser && branded.showServicesTabs && <BusinessTabs />}
          <SearchBookingsHomepageForm
            contract={contract}
            user={authUser}
            onSubmit={this.searchBookings}
            classname="search-booking-homepage"
            i18n={this.props.i18n}
            requestError={companyIdError}
          />
        </div>
      );
    }

    if (!authUser && branded.loginMsgKey) {
      return <LoginMsgForm msgKey={branded.loginMsgKey} />;
    }

    if (branded.restrictedAccess && !authUser) {
      return (
        <InviteCodeForm
          onSubmit={this.handleInviteSubmit}
          onRetry={this.props.removeInviteError}
          isInPopup={false}
        />
      );
    }
  }

  render() {
    const { branded } = this.props.brand || {};

    const tagLine =
      safe(() => branded.productTagLinei18n[this.props.i18n.locale]) ||
      safe(() => branded.productTagLine);

    const { companyIdFetching } = this.props.user || {};
    const contract = branded.contract || {};
    const isRV = safe(() => contract.interfaceConfig.replacementVehicleDisplay);

    return (
      <div className="homepage">
        {(this.props.booking.searchBookingsFetching || companyIdFetching) && (
          <div className="loading-booking-overlay">
            <img src={require('./../assets/images/loader.gif')} alt="" />
          </div>
        )}

        <div className="container brand">
          <h1>
            <FormattedMessage
              id="home.welcome"
              values={{ productName: branded.productName }}
            />
          </h1>
          {!isRV && <h2>{tagLine}</h2>}
        </div>
        <div className="container search-container">
          <div className="search-wrapper">{this.showMainContent()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state, brandKeycloak: brandKeycloakSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...PopupActions,
      ...UserActions,
      dispatch
    },
    dispatch
  );
}

Homepage = connect(mapStateToProps, mapDispatchToProps)(Homepage);

export default (props) => {
  return <Homepage {...props} onValidateAccountPage={!useMatch(`/${validateAccount}`)} />;
};
