import React, { Component } from 'react';
import classnames from 'classnames';
import RegisterCredentials from './account/RegisterCredentials';
import PersonalDetailsForm from './account/PersonalDetails/PersonalDetailsForm';
import DrivingLicenceForm from './account/DrivingLicenceForm';
import IdentityDocumentForm from './account/IdentityDocumentForm';
import SelfieForm from './account/SelfieForm';
import PaymentInfo from './account/PaymentInfo';
import config from '../constants/config';

import {
  localeSelector,
  acceptTermsValuesSelector,
  selectCustomFieldsInitialValues,
  customFieldsValuesSelector,
  drivingLicenceFormInitialValuesSelector,
  identityDocumentFormInitialValuesSelector,
  messagesSelector,
  personalDetailsInitialValuesSelector,
  profileCustomFieldsSelector,
  registerUpcomingBookingsStatusSelector,
  selfieFormInitialValuesSelector,
  specialFieldsInitialValuesSelector,
  specialFieldsValuesSelector,
  userIdSelector,
  canAddNewAccountSelector,
  isProfileIncompleteUserSelector,
  isRegisterIncompleteUserSelector,
  brandCompanyInitialValuesSelector,
  memberFieldsSelector,
  memberCustomFieldsValuesSelector,
  drivingLicenseAllowedSelector,
  identityDocumentAllowedSelector,
  ssoTokensSelector
} from '../helpers/selectors';

import { bindActionCreators } from 'redux';
import * as UserActions from '../actions/user';
import * as PopupActions from '../actions/popup';
import * as CreditCardActions from '../actions/creditCard';
import { getPreBookingStatus } from '../actions/booking';
import { connect } from 'react-redux';

import {
  filterCompanyCustomFields,
  formatBirthDateForApi,
  getObjectFromQuery
} from '../helpers';

import { DOCUMENT_STATUS, WORLDPAY_PAYMENT_AUTHORIZED } from '../constants/backend';
import MoreInfo from './account/MoreInfo';
import RegisterSuccess from './account/RegisterSuccess';
import { browserHistory, showApiError } from '../helpers/components';
import _size from 'lodash/size';
import _mergeWith from 'lodash/mergeWith';

import {
  getAppObj,
  getFileIdFromField,
  getMessage as t,
  isRMF_companyId,
  isSubscribePage,
  removeQueryFromUrl,
  setBackFile,
  setFrontFile,
  setSelfieFile,
  toYearDateApi,
  isEmpty,
  safe,
  isValidId,
  navigateToHomePage
} from '../utils/utils';

import { cleanDeep } from '../utils/cleanDeep';
import { createCustomFieldsArray } from '../actions/customFields/helpers';
import { getLocationProp } from './partials/LocationInput/utils';

import {
  formatSpecialFieldsForApiCall,
  formatAcceptTermsForApiCall,
  parseAddressForApi
} from '../helpers/formatters';

import { addErrorMsg, addInfoMsg } from '../utils/flashMessage/creator';
import { setRegisterExternalUserStatus, verifyUser } from '../actions/keycloak';
import { clearLoginPending, setLoginPending } from '../utils/keycloak';

export class Register extends Component {
  constructor(props) {
    super(props);

    this.initState(props);
    this.setVars();
    this.bindFunctions();
    this.startLoader();
  }

  initState() {
    const stepsData = {
      credentials: {},
      personalInfo: {},
      drivingLicense: {},
      identityDocument: {},
      selfie: {},
      customFields: {},
      acceptTerms: {},
      specialFields: {}
    };

    this.state = { currentStep: 0, ...stepsData };
  }

  setVars() {
    this.LOCAL_STORAGE_KEY = 'REGISTER_DATA';
    this.numberOfVisibleSteps = 0;
    this.steps = [];
  }

  bindFunctions() {
    // save data functions
    this.saveCredentialsData = this.saveCredentialsData.bind(this);
    this.savePersonalInfoData = this.savePersonalInfoData.bind(this);
    this.saveDrivingLicenseData = this.saveDrivingLicenseData.bind(this);
    this.saveIdentityDocumentData = this.saveIdentityDocumentData.bind(this);
    this.saveSelfieData = this.saveSelfieData.bind(this);
    this.saveDataInLocalStorage = this.saveDataInLocalStorage.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);

    // page functions
    this.credentialsPage = this.credentialsPage.bind(this);
    this.personalInfoPage = this.personalInfoPage.bind(this);
    this.drivingLicensePage = this.drivingLicensePage.bind(this);
    this.identityDocumentPage = this.identityDocumentPage.bind(this);
    this.selfiePage = this.selfiePage.bind(this);
    this.paymentPage = this.paymentPage.bind(this);
    this.moreInfoPage = this.moreInfoPage.bind(this);
    this.registerSuccessPage = this.registerSuccessPage.bind(this);

    // other
    this.onNextCallback = this.onNextCallback.bind(this);
    this.hanldeSuccessLeave = this.hanldeSuccessLeave.bind(this);
  }

  componentDidUpdate(pp) {
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp, init) {
    this.handleUserInitialValues();
    this.handleRenaultFranceUser(pp, init);
    this.handleLogout(pp, init);
    this.handleSsoOnly(pp, init);
    this.updateProfilesData(pp, init);
    this.createSteps(pp, init);
  }

  handleWorldpayCasesOnLoad() {
    if (this.parseReturnFromWorldpay()) {
      this.isReturnFromWorldpay = true;
    } else {
      this.handleExternalUserOnLoad();
    }
  }

  // place after: isReturnFromWorldpay (handleWorldpayCasesOnLoad)
  initPropsData() {
    this.componentPropsUpdated(this.props, true);
  }

  componentDidMount() {
    this.handleWorldpayCasesOnLoad();
    this.restoreStateAfterRemount();
    this.getCompanyDataOnLoad();
    this.initPropsData();
  }

  componentWillUnmount() {
    const {
      saveRegisterInnerStateData,
      clearRegisterCompanyData,
      customFieldsValues,
      specialFieldsValues,
      acceptTermsValues,
      clearProfileFormData,
      autoUpdateRegisterIncompleteStatus
    } = this.props;

    if (isSubscribePage()) {
      saveRegisterInnerStateData({
        ...this.state,
        customFields: customFieldsValues,
        specialFields: specialFieldsValues,
        acceptTerms: acceptTermsValues
      });
    } else {
      this.getProfilesDataOnLeave();
      clearRegisterCompanyData();
      clearProfileFormData();
      autoUpdateRegisterIncompleteStatus();
      window.localStorage.removeItem(this.LOCAL_STORAGE_KEY);
    }
  }

  restoreStateAfterRemount() {
    const { registerInnerStateData, clearRegisterInnerStateData } = this.props;

    if (registerInnerStateData) {
      this.setState(registerInnerStateData);
      clearRegisterInnerStateData();
    }
  }

  startLoader() {
    this.loadingCount = 0;
    this.props.setSubscribeLoaderState(true);
  }

  startFetch() {
    this.loadingCount += 1;
    clearTimeout(this.loaderTimeout);
  }

  finishFetch() {
    this.loadingCount -= 1;
    if (this.loadingCount <= 0) {
      this.loaderTimeout = setTimeout(
        () => this.props.setSubscribeLoaderState(false),
        config.appLoaderMinTime
      );
    }
  }

  // Incomplete user functions -Start-

  getCreditCardStatus() {
    const { getCreditCardInfo, userId } = this.props;

    getCreditCardInfo(userId).then((data) => {
      if (data?.payload?.status === 200) {
        this.setState({ creditCardPresent: true });
      }
    });
  }

  handleExternalUserOnLoad() {
    if (this.props.isExternalIncompleteUser) {
      const email = safe(() => getAppObj().keycloak?.idTokenParsed?.email);
      this.setState({ credentials: { email } });
    }
  }

  setInitialValues(values) {
    const customizer = (objValue, srcValue) => {
      if (isEmpty(srcValue)) return objValue;
    };

    this.newInitialState = _mergeWith(this.newInitialState, values, customizer);
    this.setState(this.newInitialState);
  }

  handleIncompleteUserInitialValues() {
    if (!this.incompleteUserInitialValuesSet) {
      if (this.props.isRegisterIncompleteUser) {
        const {
          personalInfo,
          drivingLicense,
          identityDocument,
          selfie,
          customFields,
          specialFields
        } = this.props.userInitialValues;

        this.incompleteUserInitialValuesSet = true;
        this.getCreditCardStatus();

        this.setInitialValues({
          personalInfo,
          drivingLicense,
          identityDocument,
          selfie,
          customFields,
          specialFields
        });
      }
    }
  }

  handleProfileUserInitialValues() {
    if (!this.profileUserInitialValuesSet) {
      if (this.props.isProfileIncompleteUser) {
        const { personalInfo, drivingLicense, identityDocument, selfie } =
          this.props.userInitialValues;

        this.profileUserInitialValuesSet = true;

        this.setInitialValues({
          personalInfo,
          drivingLicense,
          identityDocument,
          selfie
        });
      }
    }
  }

  handleCompanyRulesInitialValues() {
    if (!this.companyRulesInitialValuesSet) {
      const { brandCompanyInitialValues } = this.props;

      if (brandCompanyInitialValues) {
        this.companyRulesInitialValuesSet = true;
        this.setInitialValues({ ...brandCompanyInitialValues });
      }
    }
  }

  handleUserInitialValues() {
    this.newInitialState = {};

    if (!this.isReturnFromWorldpay) {
      this.handleCompanyRulesInitialValues();
      this.handleProfileUserInitialValues();
      this.handleIncompleteUserInitialValues();
    }
  }

  handleSsoOnly(pp, init) {
    if (this.isReturnFromWorldpay) return;

    if (
      this.isExternalUserUpdated(pp, init) ||
      this.isProfileIncompleteUserUpdated(pp, init) ||
      this.isIncompleteUserUpdated(pp, init) ||
      this.registerCompanyUpdated(pp, init)
    ) {
      const {
        ssoTokens,
        registerCompany,
        isExternalIncompleteUser: externalUser,
        isProfileIncompleteUser: profileUser,
        isRegisterIncompleteUser: incompleteUser,
        m
      } = this.props || {};

      const { ssoConfiguration } = registerCompany || {};
      const { allowRegistrationWithoutSso } = ssoConfiguration || {};
      const onlySsoAllowed = allowRegistrationWithoutSso === false;
      const profileSsoUser = profileUser && ssoTokens?.auth;
      const ssoUser = externalUser || incompleteUser || profileSsoUser;

      if (onlySsoAllowed && !ssoUser) {
        addInfoMsg(t(m, 'error.register.without.sso'));
        this.navigateToHomePage();
      }
    }
  }

  handleLogout(pp, init) {
    if (this.isReturnFromWorldpay) return;

    if (
      this.userIdUpdated(pp, init) ||
      this.isIncompleteUserUpdated(pp, init) ||
      this.isExternalUserUpdated(pp, init) ||
      this.canAddNewAccountUpdated(pp, init) ||
      this.registerCompanyUpdated(pp, init)
    ) {
      const {
        isRegisterIncompleteUser,
        isExternalIncompleteUser,
        canAddNewAccount,
        registerCompany,
        userId,
        m
      } = this.props;

      const { id: companyId } = registerCompany || {};

      if (
        userId &&
        !isRegisterIncompleteUser &&
        !isExternalIncompleteUser &&
        !canAddNewAccount &&
        !isRMF_companyId(companyId)
      ) {
        if (!this.redirectDone) {
          this.redirectDone = true;
          addInfoMsg(t(m, 'register.info.profile.creation.forbidden'));
          this.navigateToHomePage();
        }
      }
    }
  }

  // Incomplete user functions -End-

  handleRenaultFranceUser(pp, init) {
    if (this.registerCompanyUpdated(pp, init)) {
      const { id: companyId } = this.props.registerCompany || {};

      if (isRMF_companyId(companyId)) {
        this.props.setFlagToOpenRenaultFrancePopup(true);
        this.navigateToHomePage();
      }
    }
  }

  getCompanyDataOnLoad() {
    this.startFetch();
    if (isValidId(this.props.routeParams.id)) {
      this.props
        .getRegisterCompany(this.props.routeParams.id)
        .then(() => this.finishFetch());
    } else {
      this.finishFetch();
      navigateToHomePage();
      addErrorMsg(t(this.props.m, 'error.invite.code.invalid'));
    }
  }

  updateProfilesData(pp, init) {
    if (this.userIdUpdated(pp, init)) {
      if (this.props.userId) this.props.getUserProfiles();
    }
  }

  getProfilesDataOnLeave() {
    const { isProfileIncompleteUser } = this.registerSuccessProps || {};

    if (isProfileIncompleteUser) {
      this.props.getUserProfiles();
    }
  }

  registerCompanyUpdated(pp, init) {
    const { registerCompany: nextValue } = this.props;
    const { registerCompany: prevValue } = pp;
    return init || nextValue !== prevValue;
  }

  canAddNewAccountUpdated(pp, init) {
    const { canAddNewAccount: nextValue } = this.props;
    const { canAddNewAccount: prevValue } = pp;
    return init || nextValue !== prevValue;
  }

  userIdUpdated(pp, init) {
    const { userId: nextValue } = this.props;
    const { userId: prevValue } = pp;
    return init || nextValue !== prevValue;
  }

  isProfileIncompleteUserUpdated(pp, init) {
    const { isProfileIncompleteUser: nextValue } = this.props;
    const { isProfileIncompleteUser: prevValue } = pp;
    return init || nextValue !== prevValue;
  }

  isExternalUserUpdated(pp, init) {
    const { isExternalIncompleteUser: nextValue } = this.props;
    const { isExternalIncompleteUser: prevValue } = pp;
    return init || nextValue !== prevValue;
  }

  isIncompleteUserUpdated(pp, init) {
    const { isRegisterIncompleteUser: nextValue } = this.props;
    const { isRegisterIncompleteUser: prevValue } = pp;
    return init || nextValue !== prevValue;
  }

  upcomingBookingsStatusUpdated(nextProps, init) {
    const { upcomingBookingsStatus: nextValue } = nextProps;
    const { upcomingBookingsStatus: prevValue } = this.props;
    return init || nextValue !== prevValue;
  }

  drivingLicenseAllowedUpdated(nextProps, init) {
    const { drivingLicenseAllowed: nextValue } = nextProps;
    const { drivingLicenseAllowed: prevValue } = this.props;
    return init || nextValue !== prevValue;
  }

  identityDocumentAllowedUpdated(nextProps, init) {
    const { identityDocumentAllowed: nextValue } = nextProps;
    const { identityDocumentAllowed: prevValue } = this.props;
    return init || nextValue !== prevValue;
  }

  createSteps(pp, init) {
    if (
      this.registerCompanyUpdated(pp, init) ||
      this.isExternalUserUpdated(pp, init) ||
      this.isIncompleteUserUpdated(pp, init) ||
      this.upcomingBookingsStatusUpdated(pp, init) ||
      this.drivingLicenseAllowedUpdated(pp, init) ||
      this.identityDocumentAllowedUpdated(pp, init) ||
      this.userIdUpdated(pp, init)
    ) {
      this.visibleSteps = {};
      this.steps = [];

      if (this.credentialsAllowed()) {
        this.steps.push(this.createItem(1, this.credentialsPage));
      }

      this.steps.push(this.createItem(2, this.personalInfoPage));

      if (this.props.drivingLicenseAllowed) {
        this.steps.push(this.createItem(3, this.drivingLicensePage));
      }

      if (this.props.identityDocumentAllowed) {
        this.steps.push(this.createItem(3, this.identityDocumentPage));
        this.steps.push(this.createItem(3, this.selfiePage));
      }

      this.steps.push(this.createItem(4, this.moreInfoPage));

      if (this.paymentAllowed()) {
        this.steps.push(this.createItem(4, this.paymentPage));
      }

      this.steps.push(this.createItem(4, this.registerSuccessPage));

      this.numberOfVisibleSteps = _size(this.visibleSteps);
    }
  }

  // Steps Allowed

  credentialsAllowed() {
    const { isExternalIncompleteUser, userId } = this.registerSuccessProps || this.props;
    return !userId && !isExternalIncompleteUser;
  }

  paymentAllowed() {
    const { upcomingBookingsStatus, isRegisterIncompleteUser } = this.props;
    const { hasPrivateBooking } = upcomingBookingsStatus;

    if (isRegisterIncompleteUser) {
      if (hasPrivateBooking) return true;
    }
    return false;
  }

  parseReturnFromWorldpay() {
    const storedState = this.restoreStateAfterWorldpay();
    const { worldpayRedirect } = storedState || {};

    const { m, createCreditCard, userId } = this.props;
    const { orderKey, paymentStatus } = getObjectFromQuery() || {};
    const orderCode = orderKey ? orderKey.split('^').pop() : '';

    if (orderKey) {
      if (orderCode && paymentStatus === WORLDPAY_PAYMENT_AUTHORIZED) {
        this.goToNextStep(storedState);

        if (userId) {
          createCreditCard(userId, orderCode).then((data) => {
            if (data.type === 'card/CREATE_SUCCESS') {
              addInfoMsg(t(m, 'info.worldpay.return.success'));
            }
          });
        }
      } else if (paymentStatus) {
        addErrorMsg(t(m, 'error.credit.card.refused'));
      } else {
        addErrorMsg(t(m, 'label.generic.error'));
      }
    }

    if (worldpayRedirect) {
      removeQueryFromUrl();
    }

    if (worldpayRedirect && !orderKey) {
      this.goToNextStep(storedState);
    }

    return worldpayRedirect;
  }

  restoreStateAfterWorldpay() {
    try {
      const storedState = JSON.parse(window.localStorage.getItem(this.LOCAL_STORAGE_KEY));
      if (!storedState) return this.state;

      const {
        userId,
        isRegisterIncompleteUser,
        isExternalIncompleteUser,
        successData,
        ...parsedState
      } = storedState;

      this.setState(parsedState);

      if (isRegisterIncompleteUser) {
        this.props.setRegisterIncompleteStatus(isRegisterIncompleteUser);

        this.startFetch();
        this.props.getPreBookingStatus(userId).then(() => {
          this.finishFetch();
        });
      }

      if (userId) {
        this.props.getUserInfo();
      }

      if (isExternalIncompleteUser) {
        this.props.setRegisterExternalUserStatus(true);
      }

      this.registerSuccessProps = successData;

      return storedState;
    } catch (e) {
      return this.state;
    }
  }

  createItem(group, pageFunction) {
    this.visibleSteps[group] = true;
    return { visibleStep: _size(this.visibleSteps), pageFunction };
  }

  // do not pass as a callback
  goToNextStep(storedState) {
    const { currentStep, steps: storedSteps } = storedState || this.state;
    const steps = storedSteps || this.steps;
    const voidStep = steps.length;

    let nextStep = currentStep + 1;
    const nextStepIsMissing = nextStep === voidStep;

    if (!storedState && nextStepIsMissing) nextStep = 0;

    this.setState({ currentStep: nextStep });
    window.scrollTo(0, 0);
  }

  onNextCallback() {
    this.goToNextStep();
  }

  // save data functions

  saveCredentialsData(data) {
    this.setState({ credentials: data });
    this.goToNextStep();
  }

  savePersonalInfoData(data) {
    this.setState({ personalInfo: data });
    this.goToNextStep();
  }

  saveDrivingLicenseData(data) {
    this.setState({ drivingLicense: data });
    this.goToNextStep();
  }

  saveIdentityDocumentData(data) {
    this.setState({ identityDocument: data });
    this.goToNextStep();
  }

  saveSelfieData(data) {
    this.setState({ selfie: data });
    this.goToNextStep();
  }

  saveDataInLocalStorage() {
    const { userId, isRegisterIncompleteUser, isExternalIncompleteUser } = this.props;

    if (isExternalIncompleteUser) {
      setLoginPending();
    }

    const packedItems = JSON.stringify({
      successData: this.registerSuccessProps,
      currentStep: this.state.currentStep,
      isRegisterIncompleteUser,
      isExternalIncompleteUser,
      worldpayRedirect: true,
      steps: this.steps,
      userId
    });

    window.localStorage.setItem(this.LOCAL_STORAGE_KEY, packedItems);
  }

  setRegisterData(customFields = {}, specialFields = {}, acceptTerms = {}) {
    const { registerCompany, locale, memberFields, memberValues } = this.props;

    const { credentials, personalInfo, drivingLicense, identityDocument, selfie } =
      this.state;

    const { email, password } = credentials;

    const {
      licenceNumber,
      deliveranceDate,
      cityDeliverance,
      expirationDateNotPresent,
      expirationDate,
      documentFront: drivingFront,
      documentBack: drivingBack
    } = drivingLicense;

    const { documentFront: identityFront, documentBack: identityBack } = identityDocument;
    const { selfieImage } = selfie;

    const { firstName, lastName, phoneNumber, civility, birthDate } = personalInfo;
    const { phonePrefix, phoneSuffix } = phoneNumber || {};
    const { id } = registerCompany || {};

    let payload = {
      companyId: id,
      locale,
      login: email,
      password,
      firstName,
      lastName,
      civility,
      birthDate: formatBirthDateForApi(birthDate),
      address: parseAddressForApi(personalInfo),
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      }
    };

    const fieldsPayload = createCustomFieldsArray(customFields, memberValues);
    payload.memberCustomValues = filterCompanyCustomFields(memberFields, fieldsPayload);

    if (this.props.drivingLicenseAllowed) {
      payload.drivingLicence = {
        licenceNumber,
        cityDeliverance: getLocationProp(cityDeliverance, ['city', 'streetName']),
        deliveranceDate: toYearDateApi(deliveranceDate),
        expirationDate: toYearDateApi(!expirationDateNotPresent && expirationDate),
        files: [
          setFrontFile(getFileIdFromField(drivingFront)),
          setBackFile(getFileIdFromField(drivingBack))
        ]
      };
    }

    if (this.identityDocumentAllowed) {
      payload.identityDocument = {
        files: [
          setFrontFile(getFileIdFromField(identityFront)),
          setBackFile(getFileIdFromField(identityBack)),
          setSelfieFile(getFileIdFromField(selfieImage))
        ]
      };
    }

    payload = formatSpecialFieldsForApiCall(payload, specialFields, registerCompany);
    payload = formatAcceptTermsForApiCall(payload, acceptTerms, registerCompany);

    return cleanDeep(payload);
  }

  setSuccessData() {
    const {
      isRegisterIncompleteUser,
      isExternalIncompleteUser,
      isProfileIncompleteUser,
      userId
    } = this.props;
    const { credentials, personalInfo } = this.state;
    const { firstName, login } = personalInfo || {};
    const { email } = credentials || {};

    this.registerSuccessProps = {
      isRegisterIncompleteUser,
      isProfileIncompleteUser,
      isExternalIncompleteUser,
      email: email || login,
      firstName,
      userId
    };
  }

  handleSubmitSuccess() {
    this.setSuccessData();
    this.loginExternalUser();
    this.goToNextStep();
  }

  handleSubmitFail() {
    this.setState({ currentStep: 0 });
  }

  showErrorMsg(data) {
    showApiError(this.props.m, data);
  }

  handleRegisterUser(customFields, specialFields, acceptTerms) {
    const { register, registerExternalUser, isExternalIncompleteUser } = this.props;
    const registerAction = isExternalIncompleteUser ? registerExternalUser : register;
    const successTypes = ['user/REGISTER_SUCCESS', 'user/REGISTER_SSO_SUCCESS'];

    registerAction(this.setRegisterData(customFields, specialFields, acceptTerms)).then(
      (data) => {
        if (successTypes.includes(data.type)) {
          this.handleSubmitSuccess();
        } else {
          this.showErrorMsg(data);
          this.handleSubmitFail();
        }
      }
    );
  }

  handleUpdateProfile(customFields, specialFields, acceptTerms) {
    const { userId, updateInformations, createCreditCard } = this.props;
    const registerData = this.setRegisterData(customFields, specialFields, acceptTerms);
    const { paymentOrderCode, ...parsedData } = registerData;

    updateInformations(userId, parsedData).then((data) => {
      if (data.type === 'user/UPDATE_INFO_SUCCESS') {
        this.handleSubmitSuccess();
        if (paymentOrderCode) createCreditCard(userId, paymentOrderCode);
      } else this.handleSubmitFail();
    });
  }

  handleLinkProfile(customFields, specialFields, acceptTerms) {
    this.props
      .linkUserProfile(this.setRegisterData(customFields, specialFields, acceptTerms))
      .then((data) => {
        if (data.type === 'user/LINK_PROFILE_SUCCESS') {
          this.handleSubmitSuccess();
          this.props.getUserInfo();
        } else {
          this.showErrorMsg(data);
          this.handleSubmitFail();
        }
      });
  }

  handleCreateAccount({
    customFieldsValues: customFields,
    acceptTermsValues: acceptTerms,
    specialFieldsValues: specialFields
  }) {
    const { isRegisterIncompleteUser, isProfileIncompleteUser } = this.props;

    this.setState({ customFields });
    this.setState({ specialFields });
    this.setState({ acceptTerms });

    if (isRegisterIncompleteUser) {
      this.handleUpdateProfile(customFields, specialFields, acceptTerms);
    } else if (isProfileIncompleteUser) {
      this.handleLinkProfile(customFields, specialFields, acceptTerms);
    } else {
      this.handleRegisterUser(customFields, specialFields, acceptTerms);
    }
  }

  navigateToHomePage() {
    browserHistory.push('/');
  }

  navigateToBookings() {
    browserHistory.push('/my-bookings');
  }

  // page functions

  credentialsPage() {
    const { credentials } = this.state;

    return (
      <RegisterCredentials
        onSubmit={this.saveCredentialsData}
        initialValues={credentials}
      />
    );
  }

  personalInfoPage() {
    const { personalInfo } = this.state;

    return (
      <PersonalDetailsForm
        onSubmit={this.savePersonalInfoData}
        isSubscribePage
        initialValues={personalInfo}
      />
    );
  }

  drivingLicensePage() {
    const { drivingLicense } = this.state;

    return (
      <DrivingLicenceForm
        onSubmit={this.saveDrivingLicenseData}
        isSubscribePage
        initialValues={drivingLicense}
      />
    );
  }

  identityDocumentPage() {
    const { identityDocument } = this.state;

    return (
      <IdentityDocumentForm
        onSubmit={this.saveIdentityDocumentData}
        isSubscribePage
        initialValues={identityDocument}
      />
    );
  }

  selfiePage() {
    const { selfie } = this.state;

    return (
      <SelfieForm onSubmit={this.saveSelfieData} isSubscribePage initialValues={selfie} />
    );
  }

  paymentPage() {
    const { creditCardPresent } = this.state;
    const { upcomingBookingsStatus } = this.props;
    const { hasPrivateBooking } = upcomingBookingsStatus;

    return (
      <PaymentInfo
        onSubmit={this.saveDataInLocalStorage}
        onNext={this.onNextCallback}
        paymentCode={creditCardPresent}
        creditCardMandatory={hasPrivateBooking}
        isSubscribePage
      />
    );
  }

  moreInfoPage() {
    const { acceptTerms, customFields, specialFields } = this.state;

    return (
      <MoreInfo
        onSubmit={this.handleCreateAccount}
        isSubscribePage
        customFieldsInitialValues={customFields}
        acceptTermsInitialValues={acceptTerms}
        specialFieldsInitialValues={specialFields}
      />
    );
  }

  loginExternalUser() {
    if (this.props.isExternalIncompleteUser) {
      this.props.verifyUser();
      clearLoginPending();
    }
  }

  hanldeSuccessLeave() {
    if (this.props.preBookingStatus) {
      this.navigateToBookings();
    } else {
      this.navigateToHomePage();
    }
  }

  registerSuccessPage() {
    return (
      <RegisterSuccess
        onSubmit={this.hanldeSuccessLeave}
        {...this.registerSuccessProps}
      />
    );
  }

  stepLine(index) {
    if (index !== this.numberOfVisibleSteps - 1) {
      return <div key={index + '.sub'} className="scope-step-line" />;
    }
  }

  getActiveIndex(index) {
    const { currentStep } = this.state;
    const stepObj = this.steps[currentStep] || this.steps[0];
    if (stepObj) return stepObj.visibleStep === index + 1;
  }

  getStepContainer(index) {
    return (
      <div
        key={index}
        className={classnames('scope-step', {
          'sub-class-active': this.getActiveIndex(index)
        })}
      >
        {index + 1}
      </div>
    );
  }

  renderSteps() {
    const visibleSteps = [];

    for (let index = 0; index < this.numberOfVisibleSteps; index++) {
      visibleSteps.push(this.getStepContainer(index));
      visibleSteps.push(this.stepLine(index));
    }

    return visibleSteps;
  }

  renderCurrentStep() {
    const { currentStep } = this.state;
    const stepObj = this.steps[currentStep] || this.steps[0];
    if (stepObj) return stepObj.pageFunction();
  }

  getCompanyName() {
    const { registerCompany, m } = this.props;
    const { name } = registerCompany || {};
    return name || t(m, 'generic.company');
  }

  getTitlePart() {
    const {
      m,
      isRegisterIncompleteUser,
      isProfileIncompleteUser,
      isExternalIncompleteUser
    } = this.props;

    let titlePart = 'part.create.account.for';

    if (isProfileIncompleteUser) titlePart = 'part.link.account.to';

    if (isRegisterIncompleteUser || isExternalIncompleteUser) {
      titlePart = 'part.complete.register.for';
    }

    return <div className="scope-title">{t(m, titlePart)}</div>;
  }

  render() {
    const { currentStep } = this.state;
    const lastStep = currentStep === this.steps.length - 1;

    return (
      <div className={classnames('register-page', 'profile-page')}>
        <div
          className={classnames('scope-page-header', { 'sub-class-last-step': lastStep })}
        >
          <div className="scope-title-container">
            {this.getTitlePart()}
            <div className="scope-company-name">{this.getCompanyName()}</div>
          </div>
          <div className="scope-steps-container">{this.renderSteps()}</div>
        </div>
        <div className={classnames('scope-content', 'active')}>
          {this.renderCurrentStep()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    keycloak,
    booking: { preBookingStatus }
  } = state;

  const { isExternalIncompleteUser } = keycloak;
  const { registerCompany, registerInnerStateData } = user;
  const customFields = profileCustomFieldsSelector(state);

  return {
    registerCompany,
    preBookingStatus,
    registerInnerStateData,
    isExternalIncompleteUser,
    isRegisterIncompleteUser: isRegisterIncompleteUserSelector(state),
    isProfileIncompleteUser: isProfileIncompleteUserSelector(state),
    ssoTokens: ssoTokensSelector(state),
    m: messagesSelector(state),
    userId: userIdSelector(state),
    locale: localeSelector(state),
    memberValues: memberCustomFieldsValuesSelector(state),
    memberFields: memberFieldsSelector(state),
    upcomingBookingsStatus: registerUpcomingBookingsStatusSelector(state),
    canAddNewAccount: canAddNewAccountSelector(state),
    acceptTermsValues: acceptTermsValuesSelector(state),
    customFieldsValues: customFieldsValuesSelector(state),
    specialFieldsValues: specialFieldsValuesSelector(state),
    brandCompanyInitialValues: brandCompanyInitialValuesSelector(state),
    drivingLicenseAllowed: drivingLicenseAllowedSelector(state),
    identityDocumentAllowed: identityDocumentAllowedSelector(state),
    userInitialValues: {
      personalInfo: personalDetailsInitialValuesSelector(state),
      drivingLicense: drivingLicenceFormInitialValuesSelector(state),
      identityDocument: identityDocumentFormInitialValuesSelector(state),
      selfie: selfieFormInitialValuesSelector(state),
      customFields: selectCustomFieldsInitialValues(customFields),
      specialFields: specialFieldsInitialValuesSelector(state)
    }
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...UserActions,
      ...PopupActions,
      ...CreditCardActions,
      setRegisterExternalUserStatus,
      getPreBookingStatus,
      verifyUser
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
